import React from 'react';
// import PropTypes from 'prop-types';
import { Tab } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Row, Container } from '../modules/Grid';
import FaqNav from '../faqs/FaqNav';
import FaqList from '../faqs/FaqList';
import { Heading } from '../modules/Typography';
import faqs from '../faqs/faqConfig'; ;

export const FaqContext = React.createContext();

const Faqs = props => {
    return (
        <FaqContext.Provider value={{ faqs }}>
            <div className='Faqs'>
                <Helmet><title>Disney Launchpad: Shorts Incubator | FAQs</title></Helmet>
                <Container>
                    <Heading number={1} className='Faqs__title'>FAQ</Heading>
                    <Tab.Container defaultActiveKey={faqs[0].section}>
                        <Row>
                            <FaqNav />
                            <FaqList />
                        </Row>
                    </Tab.Container>
                </Container>
            </div>
        </FaqContext.Provider>
    );
};

Faqs.propTypes = {

};

export default Faqs;
