import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { UPDATE_APPLICANT, REMOVE_APPLICANT } from '../../queries';
import TextField from '../modules/TextField';
import TextArea from '../modules/TextArea';
import Select from '../modules/Select';
import Button from '../modules/Button';
import { Heading, Body, Caption } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const ApplicantForm = props => {
    const { states, hearOptions, showErrors, refetch } = useContext(ApplicationContext);
    const { applicant } = props;
    const [updateApplicant] = useMutation(UPDATE_APPLICANT);
    const [removeApplicant] = useMutation(REMOVE_APPLICANT, { variables: { id: applicant.id } });
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setBirthDate] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [type, setType] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [passionWords, setPassionWords] = useState('');
    const [collaborateWords, setCollaborateWords] = useState('');
    const [bioWords, setBioWords] = useState('');
    const [howHeard, setHowHeard] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const dateTest = (value) => {
        const re = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        return re.test(value) && moment(value).isBefore(moment()) && moment(value).isAfter(moment('1/1/1921'));
    };

    const emailTest = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    };

    const phoneTest = (value) => {
        const re = /^\d{3}-\d{3}-\d{4}$/;
        return re.test(value);
    };

    const updateErrors = () => {
        let errors = [];
        const params = applicant.params || [];
        ['firstName', 'lastName', 'state', 'zipcode'].forEach(key => {
            if (applicant[key]) {
                errors = errors.filter(error => error !== key);
            } else {
                errors = [...errors, key];
            }
        });
        ['passion', 'collaborate', 'bio', 'howHeard'].forEach(key => {
            if (params.find(param => param.key === key) && params.find(param => param.key === key).value) {
                errors = errors.filter(error => error !== key);
            } else {
                errors = [...errors, key];
            }
        });
        if (dateTest(applicant.dob)) {
            errors = errors.filter(error => error !== 'dob');
        } else {
            errors = [...errors, 'dob'];
        }
        if (emailTest(applicant.email)) {
            errors = errors.filter(error => error !== 'email');
        } else {
            errors = [...errors, 'email'];
        }
        if (phoneTest(applicant.phoneNumber)) {
            errors = errors.filter(error => error !== 'phoneNumber');
        } else {
            errors = [...errors, 'phoneNumber'];
        }
        setErrors(errors);
    };

    useEffect(() => {
        setFirstName(applicant.firstName || '');
        setLastName(applicant.lastName || '');
        setEmail(applicant.email || '');
        setBirthDate(applicant.dob || '');
        setState(applicant.state || '');
        setZipCode(applicant.zipcode || '');
        setType(applicant.type || '');
        setPhoneNumber(applicant.phoneNumber || '');
        const { params } = applicant;
        if (params) {
            const passion = params.find(param => param.key === 'passion');
            const collaborate = params.find(param => param.key === 'collaborate');
            const bio = params.find(param => param.key === 'bio');
            const howHeard = params.find(param => param.key === 'howHeard');
            setPassionWords(passion ? passion.value : '');
            setCollaborateWords(collaborate ? collaborate.value : '');
            setBioWords(bio ? bio.value : '');
            setHowHeard(howHeard ? howHeard.value : '');
        }
        updateErrors();
    }, [applicant]);

    useEffect(() => {
        props.handleValid(props.idx, !errors.length);
    }, [errors]);

    const countWords = text => {
        return text.split(' ').length;
    };
    const passionCount = countWords(passionWords);
    const collabCount = countWords(collaborateWords);
    const bioCount = countWords(bioWords);

    const handlePassion = (e) => {
        const { value } = e.target;
        setPassionWords(value.split(' ').slice(0, 500).join(' '));
    };
    const handleCollab = (e) => {
        const { value } = e.target;
        setCollaborateWords(value.split(' ').slice(0, 500).join(' '));
    };
    const handleBio = (e) => {
        const { value } = e.target;
        setBioWords(value.split(' ').slice(0, 500).join(' '));
    };

    const handleBlur = (id, isValid) => {
        if (!isValid) {
            setErrors(errors => [...errors, id]);
        } else {
            setErrors(errors => errors.filter(error => error !== id));
        }
        handleSave();
    };

    const handleTextBlur = (e) => {
        const { value } = e.target;
        const id = e.target.id.split('-')[0];
        handleBlur(id, value);
    };

    const handleDateBlur = e => {
        const { value } = e.target;
        const isValid = dateTest(value);
        handleBlur('dob', isValid);
    };

    const handleEmailBlur = e => {
        const { value } = e.target;
        const isValid = emailTest(value);
        handleBlur('email', isValid);
    };

    const handlePhoneBlur = e => {
        const { value } = e.target;
        const isValid = phoneTest(value);
        handleBlur('phoneNumber', isValid);
    };

    const handlePhoneChange = e => {
        let { value } = e.target;
        const digits = /^\d{0,3}$/;
        const areaCode = /^\d{3}$/;
        const prefix = /^\d{3}-\d{3}$/;
        const ext = /^\d{3}-\d{3}-\d{0,4}$/;
        const areaCodeDashes = /^\d{3}-$/;
        const prefixDashes = /^\d{3}-\d{3}-$/;

        if (areaCode.test(value) || prefix.test(value)) {
            value = `${value}-`;
        } else if (areaCodeDashes.test(value) || prefixDashes.test(value)) {
            value = value.slice(0, value.length - 1);
        } else if (!ext.test(value) && !digits.test(value)) {
            value = value.replace(/[^(0-9)-]/g, '');
        }
        setPhoneNumber(value.slice(0, 12));
    };

    const handleDateChange = e => {
        let { value } = e.target;
        const digits = /^\d{0,3}$/;

        const month = /^\d{2}$/;
        const day = /^\d{2}\/\d{2}$/;
        const year = /^\d{2}\/\d{2}\/\d{0,4}$/;
        const monthSlashes = /^\d{2}\/$/;
        const daySlashes = /^\d{2}\/\d{2}\/$/;

        if (month.test(value) || day.test(value)) {
            value = `${value}/`;
        } else if (monthSlashes.test(value) || daySlashes.test(value)) {
            value = value.slice(0, value.length - 1);
        } else if (!year.test(value) && !digits.test(value)) {
            value = value.replace(/[^(0-9)/]/g, '');
        }
        setBirthDate(value.slice(0, 10));
    };

    const handleKeyPress = e => {
        if (e.key === '/') {
            e.preventDefault();
        }
    };

    const handleSelect = ({ id, value }) => {
        if (id === 'state') {
            setState(value);
        } else if (id === 'howHeard') {
            setHowHeard(value);
        }
        if (!value) {
            setErrors(errors => [...errors, id]);
        } else {
            setErrors(errors => errors.filter(error => error !== id));
        }
        handleSave(id, value);
    };

    const handleSave = async (id, value) => {
        const newState = id && id === 'state' ? value : state;
        const newHow = id && id === 'howHeard' ? value : howHeard;
        await updateApplicant({
            variables: {
                id: applicant.id,
                applicant: {
                    firstName,
                    lastName,
                    phoneNumber,
                    email,
                    dob,
                    state: newState,
                    zipcode,
                    type,
                    params: [
                        { key: 'passion', value: passionWords },
                        { key: 'collaborate', value: collaborateWords },
                        { key: 'bio', value: bioWords },
                        { key: 'howHeard', value: newHow }

                    ]
                }
            }
        });
    };

    const handleRemove = async () => {
        setLoading(true);
        await removeApplicant();
        window.scrollTo(0, 0);
        setLoading(false);
        refetch();
    };

    let roleName = 'Director';
    if (type === 'writer') {
        roleName = 'Writer';
    } else if (type === 'both') {
        roleName = 'Writer / Director';
    }

    return (
        <div className='ApplicantForm'>
            <Row>
                <Col xs={10} md={3} lg={4} xsOffset={1} lgOffset={2}>
                    <Heading block number={2}>{roleName}</Heading>
                    <Body block>Enter personal information</Body>
                    <Body block>All fields required</Body>
                </Col>
                <Col xs={10} md={6} lg={4} xsOffset={1} lgOffset={0}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <TextField
                                label='First name'
                                id={`firstName-${props.idx}`}
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                onBlur={handleTextBlur}
                                error={showErrors && errors.includes('firstName')}
                                errorMessage='Please include your first name.'
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextField
                                label='Last name'
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                id={`lastName-${props.idx}`}
                                onBlur={handleTextBlur}
                                error={showErrors && errors.includes('lastName')}
                                errorMessage='Please include your last name.'
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextField
                                label='Email' type='email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onBlur={handleEmailBlur}
                                error={showErrors && errors.includes('email')}
                                errorMessage='Please include a valid email address.'
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextField
                                label='Date of Birth'
                                placeholder='mm/dd/yyyy'
                                value={dob}
                                onChange={handleDateChange}
                                onKeyDown={handleKeyPress}
                                onBlur={handleDateBlur}
                                error={showErrors && errors.includes('dob')}
                                errorMessage='Please include a valid birthdate.'
                            />
                        </Col>
                        <Col xs={12} sm={6} className='selector__parent'>
                            <Select
                                label='State'
                                options={states}
                                id='state'
                                tabIndex={0}
                                selectedValue={state}
                                onChange={handleSelect}
                                placeholder='Please select...'
                                error={showErrors && errors.includes('state')}
                                errorMessage='Please include your state.'
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextField
                                label='ZIP Code'
                                id={`zipcode-${props.idx}`}
                                type='number'
                                value={zipcode}
                                onChange={e => setZipCode(e.target.value)}
                                onBlur={handleTextBlur}
                                error={showErrors && errors.includes('zipcode')}
                                errorMessage='Please include your ZIP code.'
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextField
                                maxLength='14'
                                label='Phone Number'
                                placeholder='___-___-____'
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                onBlur={handlePhoneBlur}
                                error={showErrors && errors.includes('phoneNumber')}
                                errorMessage='Please include a valid phone number ___-___-____.'
                            />
                        </Col>
                    </Row>
                    <TextArea
                        label='Tell us how you come from an underrepresented background and/or have a diverse perspective. If you don’t come from an underrepresented background, tell us how you have a unique perspective (500 words).'
                        placeholder='Type here...'
                        className='ApplicantForm__textArea spacing-sm'
                        value={passionWords}
                        onChange={handlePassion}
                        id={`passion-${props.idx}`}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('passion')}
                        errorMessage='This field is required.'
                    />
                    {passionWords ? <Caption>{passionCount} word{passionCount !== 1 ? 's' : ''}</Caption> : ''}
                    <TextArea
                        label='Please tell us about a time you creatively collaborated on a writing/directing project. (500 word limit).'
                        placeholder='Type here...'
                        className='ApplicantForm__textArea spacing-sm'
                        value={collaborateWords}
                        onChange={handleCollab}
                        id={`collaborate-${props.idx}`}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('collaborate')}
                        errorMessage='This field is required.'
                    />
                    {collaborateWords ? <Caption>{collabCount} word{collabCount !== 1 ? 's' : ''}</Caption> : ''}
                    <TextArea
                        label='Please provide a brief artist biography. (250 word limit).'
                        placeholder='Type here...'
                        className='ApplicantForm__textArea spacing-sm'
                        value={bioWords}
                        onChange={handleBio}
                        id={`bio-${props.idx}`}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('bio')}
                        errorMessage='This field is required.'
                    />
                    {bioWords ? <Caption>{bioCount} word{bioCount !== 1 ? 's' : ''}</Caption> : ''}<br /><br />
                    <div className='selector__parent'>
                        <Select
                            label='How did you hear about Launchpad?'
                            id='howHeard'
                            options={hearOptions}
                            selectedValue={howHeard}
                            onChange={handleSelect}
                            placeholder='Please select...'
                            error={showErrors && errors.includes('howHeard')}
                            errorMessage='Please make a selection.'
                        />
                    </div>
                    <TextField className='hidden' />
                </Col>
            </Row>
            {props.idx !== 0 && (
                <Row>
                    <Col xs={10} md={3} lg={4} xsOffset={1} lgOffset={2}>
                        <Button type='tertiary' color='error' icon='trash-can' loading={loading} onClick={handleRemove}>Remove {roleName}</Button>
                    </Col>
                </Row>
            )}
            <hr className='Application__divider' />
        </div>
    );
};

ApplicantForm.propTypes = {
    applicant: PropTypes.object,
    handleValid: PropTypes.func,
    idx: PropTypes.number
};

export default ApplicantForm;
