import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '../modules/Button';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const ReviewApplicant = props => {
    const { hearOptions, setStep } = useContext(ApplicationContext);
    const {
        params,
        firstName,
        lastName,
        email,
        dob,
        phoneNumber,
        state,
        zipcode,
        type
    } = props.applicant || {};

    const findParamValue = (key) => {
        const param = params.find(param => param.key === key);
        return param ? param.value : 'N/A';
    };

    const passion = findParamValue('passion');
    const collaborate = findParamValue('collaborate');
    const bio = findParamValue('bio');
    const howHeard = findParamValue('howHeard');
    const hearOption = hearOptions.find(h => h.value === howHeard);
    const hearText = hearOption ? hearOption.text : 'N/A';

    return (
        <>
            <hr className='Application__divider' />
            <Row>
                <Col xs={6} xsOffset={1} lgOffset={2}>
                    <Heading block number={3} className='Application__Review__heading capitalize'>{type === 'both' ? 'Writer / Director' : type}</Heading>
                </Col>
                <Col xs={5} md={2} lg={1} mdOffset={2} lgOffset={1}>
                    <Button color='info' type='tertiary' icon='edit-page' disney onClick={() => setStep(0)}>Edit</Button>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6} xsOffset={1} lgOffset={2}>
                    <Row className='Application__Review__row'>
                        <Col xs={6} md={4}>
                            <Heading block number={4}>First Name</Heading>
                            <Body block>{firstName}</Body>
                        </Col>
                        <Col xs={6}>
                            <Heading block number={4}>Last Name</Heading>
                            <Body block>{lastName}</Body>
                        </Col>
                    </Row>
                    <Row className='Application__Review__row'>
                        <Col xs={6} md={4}>
                            <Heading block number={4}>Email Address</Heading>
                            <Body block>{email}</Body>
                        </Col>

                    </Row>
                    <Row className='Application__Review__row'>
                        <Col xs={6} md={4}>
                            <Heading block number={4}>Date of Birth</Heading>
                            <Body block>{dob !== 'N/A' ? moment(dob).format('L') : 'N/A'}</Body>
                        </Col>
                        <Col xs={6}>
                            <Heading block number={4}>Phone Number</Heading>
                            <Body block>{phoneNumber}</Body>
                        </Col>
                    </Row>
                    <Row className='Application__Review__row'>
                        <Col xs={6} md={4}>
                            <Heading block number={4}>State</Heading>
                            <Body block>{state}</Body>
                        </Col>
                        <Col xs={6} md={4}>
                            <Heading block number={4}>ZIP Code</Heading>
                            <Body block>{zipcode}</Body>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='Application__Review__row'>
                <Col xs={11} lg={4} xsOffset={1} lgOffset={2}>
                    <Heading block number={4}>Tell us how you come from an underrepresented background and/or have a diverse perspective. If you don’t come from an underrepresented background, tell us how you have a unique perspective (500 words).</Heading>
                    <Body block>{passion}</Body>
                </Col>
                <Col xs={11} lg={4} xsOffset={1}>
                    <Heading block number={4}>Please tell us about a time you creatively collaborated on a writing/directing project. (500 word limit).</Heading>
                    <Body block>{collaborate}</Body>
                </Col>
            </Row>
            <Row className='Application__Review__row'>
                <Col xs={11} lg={4} xsOffset={1} lgOffset={2}>
                    <Heading block number={4}>Please provide a brief artist biography. (250 word limit).</Heading>
                    <Body block>{bio}</Body>
                </Col>
                <Col xs={11} lg={4} xsOffset={1}>
                    <Heading block number={4}>How did you hear about Launchpad?</Heading>
                    <Body block>{hearText}</Body>
                </Col>
            </Row>
        </>
    );
};
ReviewApplicant.propTypes = {
    applicant: PropTypes.object
};

export default ReviewApplicant;
