import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from '../modules/Grid';
import Typography, { Heading, Body, Caption } from '../modules/Typography';
import Box from '../modules/Box';

import directingProgram from '../../images/ctdi_programs_directing_program.jpg';
import writingProgram from '../../images/ctdi_programs_writing_program.jpg';
import programAssitantInitiative from '../../images/ctdi_programs_program_assistant_initiatives.jpeg';

import { WidthContext } from '../../contexts/WidthContextProvider';

const CtdiPrograms = (props) => {
    const { isMobile } = useContext(WidthContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const cardsContent = [
        {
            id: '001',
            imageSrc: directingProgram,
            imageAltText: 'Disney Entertainment Directing Program',
            caption: { line1: 'Disney Entertainment', line2: 'Directing Program' },
            targetUrl: 'https://www.dgectdi.com/directing-program-article'
        },
        {
            id: '002',
            imageSrc: writingProgram,
            imageAltText: 'Disney Entertainment Writing Program',
            caption: { line1: 'Disney Entertainment', line2: 'Writing Program' },
            targetUrl: 'https://www.dgectdi.com/writing-program-article'
        },
        {
            id: '003',
            imageSrc: programAssitantInitiative,
            imageAltText: 'Program Assistant Initiatives',
            caption: { line1: 'Program Assistant', line2: 'Initiatives' },
            targetUrl: 'https://www.dgectdi.com/pa-initiative'
        }
    ];

    return (
        <div className='CtdiPrograms'>
            <Helmet>
                <title>Disney Launchpad: Shorts Incubator | CTDI Programs</title>
            </Helmet>
            <Heading
                className='Directors__heading underline spacing-md'
                center
                number={2}
            >
                CTDI Programs
            </Heading>
            <Container>
                <Row xsOffset={1} className='flex align-center center pageSummary'>
                    <Col xs={12} md={12} lg={12}>
                        <Typography>
                            The Walt Disney Company offers many career opportunities for early career filmmakers, including the programs listed below:
                            <br />
                            <br />
                            Creative Talent Development & Inclusion Programs
                            <br />
                            <br />
                            Disney Entertainment Television’s Creative Talent Development & Inclusion (CTDI) team runs the flagship Disney Writing and Directing Programs. These programs provide staffing and episodic directing opportunities to television writers and directors on Disney’s scripted and unscripted series. The programs also provide mentorship, access and exposure to the Company’s creative executives and producers, and professional and career development. Eligibility requirements and application information can be found below.
                        </Typography>
                    </Col>
                </Row>
                <Row xsOffset={1} className='flex align-center center cardsContainer'>
                    {cardsContent.map((card) => (
                        <Col xs={12} md={4} lg={4}>
                            {' '}
                            <a
                                href={card.targetUrl}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Box elevated={3} padded={false} borderType='none' overflow>
                                    <img
                                        src={card.imageSrc}
                                        alt={card.altText}
                                        width='100%'
                                        height='100%'
                                    />
                                    <div className='cardCaption'>
                                        <Caption number={3} block>
                                            {card.caption.line1}
                                        </Caption>
                                        <Caption number={3} block>
                                            {card.caption.line2}
                                        </Caption>
                                    </div>
                                </Box>
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default CtdiPrograms;
