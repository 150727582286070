import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from '../modules/Grid';
import { Heading, Body, Caption } from '../modules/Typography';
import directors from '../directors/directors';
import { WidthContext } from '../../contexts/WidthContextProvider';
import csa from '../../images/csa.png';
import psshortsfest from '../../images/psshortsfest.png';
import naacp from '../../images/naacp.png';
import sydneyfilm from '../../images/sff.png';
import nflaurel from '../../images/nflaurel.png';
import rslaurel from '../../images/rslaurel.png';
import hollyshorts from '../../images/hollyff.png';

const SeasonOne = (props) => {
    const { isMobile } = useContext(WidthContext);
    const isOptedOut = OnetrustActiveGroups.includes(',C0002,');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {}, [isOptedOut]);

    return (
        <div className='Directors'>
            <Helmet>
                <title>Disney Launchpad: Shorts Incubator | Season One Directors</title>
            </Helmet>
            <Container>
                <Heading
                    className='Directors__heading underline spacing-md'
                    center
                    number={1}
                >
                    Season One
                </Heading>
                {isOptedOut && (
                    <Row className='flex center padded'>
                        <iframe
                            width='859'
                            height='488'
                            src='https://www.youtube.com/embed/tYEowXzs7xw'
                            title='YouTube video player'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                    </Row>
                )}
                <Row className='flex center padded'>
                    <Caption>All Short Films Available On Disney+</Caption>
                </Row>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Select shorts
                        </Heading>

                        <Row className='flex center'>
                            <img src={psshortsfest} />
                            <img src={hollyshorts} />
                            <img src={sydneyfilm} />
                            <img src={nflaurel} />
                            <img src={rslaurel} />
                        </Row>
                    </div>
                </div>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Nominated for
                        </Heading>
                        <Row className='flex center'>
                            <img src={naacp} />
                        </Row>
                    </div>
                </div>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Winner
                        </Heading>
                        <Row className='flex center'>
                            <img src={csa} />
                        </Row>
                    </div>
                </div>
                <hr className='Application__divider' />

                {directors.map((director) => (
                    <div key={director.name}>

                        <Row className='Directors__card'>
                            <Col xs={12} md={4} lg={2} className='Directors__headshot'>
                                <div />
                                <img
                                    src={director.image}
                                    className='Directors__headshot__image'
                                />
                            </Col>
                            <Col
                                xs={10}
                                md={6}
                                lg={9}
                                lgOffset={1}
                                className='Directors__card__text'
                            >
                                <Body className='Directors__name'>{director.name}</Body>
                                <Body
                                    number={2}
                                    className='Directors__description'
                                    dangerouslySetInnerHTML={{ __html: director.description }}
                                />
                                <br />
                                <br />
                            </Col>
                        </Row>
                        <Col className='Directors__logline'>
                            <Heading number={4}>{director.loglineName}</Heading>
                            <Col xs={10} md={6} lg={9}>
                                <span>
                                    <strong>Logline:</strong> {director.logline}
                                </span>
                            </Col>
                        </Col>
                    </div>
                ))}
            </Container>
        </div>
    );
};

export default SeasonOne;
