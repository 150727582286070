import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import Icon from '../modules/Icon';
import { Body } from '../modules/Typography';

const Faq = props => {
    const [isExpanded, setExpanded] = useState(false);

    const divClassName = isExpanded ? 'is-expanded' : '';
    return (
        <div className='Faqs__item'>
            <div
                className={'Faqs__item__title ' + divClassName}
                onClick={() => setExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                aria-controls={props.id.toString()}
            >
                <h4 className='Faqs__item__title__text'>
                    {props.title} <Icon className='Faqs__item__icon' name={isExpanded ? 'caret-down' : 'caret-right'} />
                </h4>
            </div>
            <Collapse in={isExpanded}>
                <div id={props.id.toString()} className='Faqs__item__text'>
                    <Body dangerouslySetInnerHTML={{ __html: props.description }} />
                </div>
            </Collapse>
        </div>
    );
};

Faq.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string
};

export default Faq;
