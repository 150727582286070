import React from 'react';
import { Container, Row, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import homeConfig from './homeConfig';

const HomeTimeline = () => {
    return (
        <div className='corner-patterns' id='timeline'>
            <Container>
                <Heading className='underline spacing-md' center number={2}>
                    Timeline
                </Heading>
                <Row>
                    <Col xs={12} className='spacing-md'>
                        <div className='Home__Timeline__container'>
                            <div className='Home__Timeline__inner'>
                                <div className='Home__Timeline__axis'>
                                    <div className='Home__Timeline__axis__line' />
                                    {homeConfig.timelines.map((timeline, index) => (
                                        <div className='Home__Timeline__axis__point' style={{ left: `${index * 10}% ` }} key={timeline.title}>
                                            <div className={`Home__Timeline__axis__verticalLine ${index % 2 === 0 ? 'Home__Timeline__axis__verticalLine--top' : 'Home__Timeline__axis__verticalLine--bottom'}`} />
                                            <div className={`Home__Timeline__block ${index % 2 === 0 ? 'Home__Timeline__block--top' : 'Home__Timeline__block--bottom'}`}>
                                                <Heading className='Home__Timeline__block__title' number={3}>{timeline.title}</Heading>
                                                <Body className='Home__Timeline__block__subtitle'>{timeline.description}</Body>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        className='Home__Timeline__details'
                        xs={10}
                        lg={8}
                        xsOffset={1}
                        lgOffset={2}
                    >
                        <Body><span className='bold'>PROGRAM OVERVIEW</span></Body>
                        <br />
                        <br />
                        <Body>
                            <li>Disney Launchpad starts with three (3) months of script development and select classes.</li>
                            <li>Up to eight (8) weeks of pre-production.</li>
                            <li>Up to four (4) weeks of principal photography.</li>
                            <li> Up to twelve (12) weeks of post-production.</li>
                        </Body>
                        <br />
                        <br />
                        <Body>
                            Please note this timeline is subject to change.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            If one of the candidates lives outside of the Southern California area, Disney will cover basic airfare (from the candidate’s current place of residence in the United States) and hotel expenses in connection with travel to Los Angeles for interviews.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            Acceptance into Disney Launchpad will be contingent upon the satisfactory completion of a background check and verification of eligibility, as well as Disney receiving signed originals of the following documents (which will be provided to the candidates at a later stage of the application process): (i) Director Services Agreement to be entered into by the director(s) and (ii) Script Acquisition Agreement, to be entered into by the writer(s) of the script which will be produced through Launchpad.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            Directors of the selected projects must reside in Southern California (as defined by zip codes from 90001 to 93066) for the duration of the Launchpad program. Relocation for writers will not be mandatory. A stipend (as determined by Disney) will be provided for directors currently living outside of Southern California (as defined by zip codes from 90001 to 93066) who must relocate to participate in the Launchpad program. A stipend (as determined by Disney) will be provided for writers who choose to relocate to Southern California and will be provided for the duration of their services needed for the program.
                        </Body>

                    </Col>
                </Row>
                <div className='spacing-lg Home__apply'>
                    <div className='Home__apply__title small flex column align-center'>
                        <Heading center number={3}>
                            Frequently Asked Questions
                        </Heading>
                    </div>

                    <Row className='flex align-center inherit'>
                        <Col xs={10} lg={7} xsOffset={1}>
                            <Icon className='Home__apply__text' name='help' />
                            <Body className='Home__apply__text'>
                                {' '}
                                Find out more about Disney Launchpad via our FAQs:
                            </Body>
                        </Col>
                        <Col
                            className='padded'
                            xs={8}
                            md={6}
                            lg={3}
                            xsOffset={2}
                            mdOffset={3}
                            lgOffset={0}
                        >
                            <Button
                                className='Home__apply__button'
                                type='primary'
                                size='large'
                                block
                                link='/faq'
                            >
                                View FAQs
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row className='spacing-btm'>
                    <Col xs={10} lg={12} xsOffset={1} lgOffset={0}>
                        <Heading number={4} className='Home__apply__header'>
                            Third Party Employment or Restriction
                        </Heading>
                        <Body number={2}>
                            Employment in the Disney Launchpad is subject to the conditions and terms of The Walt Disney Company and Associated Companies Confidentiality Agreement, and the obligations and commitments contained therein; and an acknowledgement by you to follow all policies, programs and practices of The Walt Disney Company and its affiliated companies, including but not limited to disclosing any services rendered to third parties that might be considered a conflict of interest under the terms of the Confidentiality Agreement or any other polices of Disney.
                        </Body>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomeTimeline;
