import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import fetch from '../../utils/fetch';
import { Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { UserContext } from '../../contexts/UserContextProvider';

const LoginModal = props => {
    const { setToken } = useContext(UserContext);
    const [showPassword, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const testEmail = (e) => {
        const { value } = e.target;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setErrors(errors.filter(error => error !== 'email'));
        } else {
            setErrors([...errors, 'email']);
        }
    };

    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };
    const handlePassword = (e) => {
        const { value } = e.target;
        setPassword(value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('/auth/sentry/login', {
                method: 'POST',
                body: {
                    username: email,
                    password
                }
            });
            if (response.error) {
                const errorDescription = response.error_description;
                if (errorDescription === 'account not found') {
                    setErrorMessage('Account Not Found');
                    setErrors(['email']);
                } else if (errorDescription.includes('Incorrect username or password')) {
                    setErrorMessage('Incorrect password');
                    setErrors(['password']);
                }
            } else {
                setToken({ accessToken: response.access_token, refreshToken: response.refresh_token });
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    return (
        <Modal show={props.open} onHide={props.handleClose} className='Login__Modal'>
            <Modal.Header closeButton>
                <Modal.Title>Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Caption className='Login__Modal__required' block number={1}>*Required Field</Caption>
                <div className='form-group'>
                    <TextField
                        placeholder='Email Address*'
                        value={email}
                        onKeyPress={handleKeyPress}
                        onChange={handleEmail}
                        onBlur={testEmail}
                        error={errors.includes('email')}
                        errorMessage={errorMessage || 'Please enter a valid email'}
                    />
                </div>
                <div className='flex align-center Login__Modal__password form-group'>
                    <TextField
                        className='right-10'
                        padded={false}
                        placeholder='Password*'
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        onKeyPress={handleKeyPress}
                        onChange={handlePassword}
                        error={errors.includes('password')}
                        errorMessage={errorMessage || 'Please enter a valid password'}
                    />
                    <Icon className='button Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className='btn-container'>
                    <Button block type='tertiary' onClick={props.handleForgot}>Forgot Password?</Button>
                    <Button block type='primary' disabled={errors.length || !email || !password} onClick={handleSubmit}>Sign In</Button>
                    <Button block type='secondary-outline' onClick={props.handleCreate}>Create Account</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

LoginModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleCreate: PropTypes.func

};

export default LoginModal;
