import React from 'react';
import { Container, Row, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import HomeCard from './HomeCard';
import homeConfig from './homeConfig';
import ApplicantCriteria from './ApplicantCriteria';

const HomeDetails = () => {
    return (
        <div className='corner-patterns' id='howToApply'>
            <Container>
                <Col lg={10} lgOffset={1}>
                    <div className='flex'>
                        <Icon name='arrow-circle-down' className='Home__details__arrow' />
                    </div>
                    <Heading className='underline spacing-md' center number={2}>
                        How To Apply
                    </Heading>
                    <Heading center className='spacing-md' number={3}>
                        When applying, you will need to provide the following:
                    </Heading>
                    <Col left lg={7} lgOffset={2}>
                        <Body className='spacing-md flex column Home__criteria'>
                            <li>Three (3) essays</li>
                            <li>
                                One (1) directing sample if applying as a director, one (1) short film script if applying as a writer, and both if applying as a writer/director.
                            </li>
                            <li>One (1) resume</li>
                            <li>Signed legal agreements</li>
                            <li>One (1) professional reference (optional)</li>
                        </Body>
                    </Col>
                </Col>
                <Row>
                    <Col lg={8} lgOffset={2}>
                        <div className='Home__Card__grid spacing-lg'>
                            <Row className='flex center'>
                                {homeConfig.cards.map((card) => (
                                    <Col
                                        className='Home__Card__column'
                                        xs={6}
                                        sm={4}
                                        key={card.title}
                                    >
                                        <HomeCard {...card} />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                    <ApplicantCriteria />
                </Row>
            </Container>
        </div>
    );
};

export default HomeDetails;
