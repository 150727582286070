import React, { useState } from 'react';
import { Body } from '../modules/Typography';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import fetch from '../../utils/fetch';
import EmailSent from './EmailSent';
import { Modal } from 'react-bootstrap';

const ForgotPasswordModal = props => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const testEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleForgot();
        }
    };

    const handleForgot = async () => {
        if (testEmail()) {
            setLoading(true);
            setErrorMessage('');
            setError(false);
            await fetch('/auth/sentry/forgot-password', {
                method: 'POST',
                body: {
                    email
                }
            });
            setLoading(false);
            setSuccess(true);
        } else {
            setLoading(false);
            setError(true);
            setErrorMessage('Please enter a valid email address.');
        }
    };

    return success ? (
        <EmailSent email={email} />
    ) : (
        <div className='ResetPassword'>
            <Modal show={props.open} onHide={props.handleClose} className='ResetPassword__Modal'>
                <Modal.Header className='flex column align-center'>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex column align-flex-start '>
                        <Body className='ResetPassword__Modal__subtitle'>Enter your email address to reset your password. An email will be sent soon.</Body>
                        <div className='flex align-center ResetPassword__Modal__password'>
                            <TextField
                                className='ResetPassword__Modal__TextField'
                                padded={false}
                                placeholder='Enter Email Address'
                                value={email}
                                error={error}
                                errorMessage={errorMessage}
                                onChange={e => [setEmail(e.target.value), setError(false)]}
                                handleKeyPress={handleKeyPress}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container'>
                        <Button block type='primary' disabled={error || !email} loading={loading} onClick={handleForgot}>Send Email</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    );
};

export default ForgotPasswordModal;
