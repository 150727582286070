export default {
    parameters: [
        'Three (3) months of script development and professional development classes, specifically designed to strengthen professional and storytelling skills.',
        'Classes for the writers and/or directors led by educational partner, the American Film Institute (AFI), as well as The Walt Disney Studios, held once or twice per week on the Disney studio lot in Burbank, California from 6:30-10 p.m. PT. Please note: these classes are mandatory for directors.',
        'The production schedule will include up to eight (8) weeks of pre-production, four (4) weeks of principal photography, and up to twelve (12) weeks of post-production. Please note: program timelines are subject to change.'
    ],
    cards: [
        {
            title: 'Script',
            subtitle: '.pdf document',
            icon: 'R',
            overlayContent:
        'An original, narrative script for a short film, up to ten (10) pages in length, which aligns tonally with the Disney brand. Adaptations, biopics, and screenplays based on existing content are not eligible but personal stories based on the filmmakers’ life and experiences are encouraged. Scripts based on Disney intellectual property are not allowed. This script will be the basis of the story you will develop and produce throughout Launchpad if selected.<br/><br/>All scripts must be submitted via PDF in a format consistent with industry standard (Courier, 12 pt. type, and standard margins).<br/><br/>Applications submitted that do not satisfy this requirement will be disqualified.'
        },
        {
            title: 'Essays',
            subtitle: 'Form field',
            icon: 'L',
            overlayContent:
        'Three essay questions (five hundred (500) word limit each) for the following prompts:<br/><br/><li>As a director applying with a team, you are eligible to apply if you come from an underrepresented background and/or have a unique perspective. Tell us how you come from an underrepresented background and/or have a unique perspective.</li><li>Please tell us about a time you creatively collaborated on a writing/directing project.</li><li>Please provide a brief artist biography.</li>'
        },
        {
            title: 'Professional Reference (Optional)',
            subtitle: '.pdf document',
            icon: 'H',
            overlayContent:
        'Applicants (including applicants who are currently employed by TWDC or any of its affiliates and who otherwise may be subject to the Company’s policy on substantive employee references), may submit a professional reference (one (1) page maximum) from a co-worker, mentor or supervisor that can attest to their professional character, personal demeanor, and collaborative spirit. This is not required.'
        },
        {
            title: 'Directing Sample',
            subtitle: 'YouTube/Vimeo links',
            icon: 'P',
            overlayContent:
        'One (1) directing sample (between five (5) minutes and thirty (30) minutes in length) that showcases your unique tone and cinematic style. This sample can be from any genre and does not need to align tonally with the Disney brand. The sample must be a scripted, live-action, narrative work with dialogue.<br/><br/>Please provide the samples via YouTube or Vimeo links with a password that is active until at least December 31, 2024.<br/><br/>Applications submitted with a directing sample that do not satisfy these requirements will be disqualified.'
        },
        {
            title: 'Resume',
            subtitle: '.pdf document',
            icon: 'Q',
            overlayContent:
        'A one (1) page resume detailing your professional work and education.<br></br>Applications submitted that do not satisfy these requirements will be disqualified.'
        },
        {
            title: 'Legal',
            subtitle: 'Signed agreements',
            icon: 'T',
            overlayContent:
        'An acknowledged Submission Agreement and Release, which includes the Disney Launchpad Program Requirements, to be agreed to online prior to submission of your application.<br></br>If you are a writer, a signed Literary Material Submission Agreement and Release to be signed by the writer(s) of the original script. Your script may have up to two (2) writers.<br></br>If your script has two (2) writers, each writer must individually sign their own Literary Material Submission Agreement and Release and you must upload both signed releases as one (1) .pdf document.<br></br>Applications submitted that do not satisfy this requirement will be disqualified.'
        }
    ],
    timelines: [
        {
            title: 'May 2, 2024',
            description: 'Applications open'
        },
        {
            title: 'June 9, 2024',
            description: 'Applications close'
        },
        {
            title: 'After October',
            description: 'Notify candidates selected for in-person interviews with writers and directors after an extensive application review process'
        },
        {
            title: 'November 2024',
            description: 'In-person interview'
        },
        {
            title: 'December 2024',
            description: 'Filmmakers chosen'
        },
        {
            title: 'February 2025',
            description: 'Program begins'
        },
        {
            title: 'February – April 2025',
            description: 'Script development & classes'
        },
        {
            title: 'May - June 2025',
            description: 'Pre-production'
        },
        {
            title: 'July 2025',
            description: 'Principal photography'
        },
        {
            title: 'July – December 2025',
            description: 'Post-production & additional classes'
        }
    ]
};
