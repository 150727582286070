import React, { useContext, useState, useEffect } from 'react';
import uploadConfig from './uploadConfig';
import Upload from './Upload';
import ScriptUpload from './ScriptUpload';
import Samples from './Samples';
import { ApplicationContext } from '../pages/Application';
import { Container, Row, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';

const ApplicationUploads = props => {
    const { application, setUploadsValid, refetch } = useContext(ApplicationContext);
    const [validUploads, setValidUploads] = useState({});
    const [validSample, setValidSample] = useState(false);

    const hasWriters = !!application.applicants.find(applicant => (applicant.type === 'writer' || applicant.type === 'both'));
    const hasDirectors = !!application.applicants.find(applicant => (applicant.type === 'director' || applicant.type === 'both'));

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setUploadsValid(!Object.values(validUploads).includes(false) && validSample);
    }, [validSample, validUploads]);

    useEffect(() => {
        setValidSample(!hasDirectors);
    }, [hasDirectors]);

    const handleValid = (name, isValid) => {
        validUploads[name] = isValid;
        setValidUploads(validUploads);
        setUploadsValid(!Object.values(validUploads).includes(false) && validSample);
    };
    return (
        <div className='ApplicationUploads'>
            <Container>
                <Row>
                    <Col xs={10} lg={8} xsOffset={1} lgOffset={2}>
                        <Heading block number={2}>Uploads</Heading>
                        <Body block>On this page, please upload a script, directing sample, resume(s), and a personal reference(s) as needed.
                        </Body>
                    </Col>
                </Row>
            </Container>
            {hasWriters && (
                <ScriptUpload
                    upload={uploadConfig.scriptUpload}
                    key='script-upload'
                    handleValid={handleValid}
                />
            )}
            {hasDirectors && (
                <Samples
                    setValidSample={setValidSample}
                />
            )}
            {uploadConfig.uploads.map((upload, idx) => (
                application.applicants.map((applicant) => (
                    <Upload
                        key={`upload-${applicant.id}-${idx}`}
                        id={`${applicant.id}-${idx}`}
                        upload={upload}
                        applicant={applicant}
                        handleValid={handleValid}
                    />
                ))
            ))}
        </div>
    );
};

export default ApplicationUploads;
