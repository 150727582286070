import React from 'react';
import { Body, Caption } from './Typography';
import PropTypes from 'prop-types';

const ProgressBar = props => {
    const { progress, error, type, className, title, subtitle, status, disabled, darkerBackground, shimmer } = props;

    const Bar = ({ className }) => (
        <div className={[
            'ProgressBar',
            `ProgressBar--${type}`,
            error ? 'ProgressBar--error' : null,
            className,
            darkerBackground ? 'ProgressBar--darker-background' : null
        ].filter(i => i).join(' ')}
        >
            {!!progress && <div className={`ProgressBar__progress ${shimmer ? 'ProgressBar__progress--shimmer' : ''}`} style={{ width: `${progress}%` }} />}
            {progress === null && <div className='ProgressBar__animation' />}
        </div>
    );

    Bar.propTypes = {
        className: PropTypes.string
    };

    // detailed progress bar
    if (title || subtitle || status) {
        return (
            <div className={`ProgressBar__wrapper ${className} ${disabled ? 'ProgressBar--disabled' : ''}`}>
                {(title || status) && (
                    <div className='ProgressBar__header'>
                        <Body number={2}>{title}</Body>
                        <Body number={2}>{status}</Body>
                    </div>
                )}
                <Bar />
                {subtitle && (
                    <div className='ProgressBar__footer'>
                        <Caption className='ProgressBar__subtitle'>{subtitle}</Caption>
                    </div>
                )}
            </div>
        );
    }

    // basic progress bar
    return <Bar className={className} />;
};

export default ProgressBar;

ProgressBar.propTypes = {
    // [0, 100] progress to display
    // if null provided, will show default loading animation
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

    // whether or not there was an error with the progress
    error: PropTypes.bool,

    // type of style for the progress bar
    type: PropTypes.oneOf(['light', 'default', 'secondary']),

    // className to pass down
    className: PropTypes.string,

    // title of progress bar to showcase in upper left hand corner
    title: PropTypes.string,

    // subtitle of progress bar to showcase in lower left hand corner
    subtitle: PropTypes.string,

    // status of progress bar to showcase in upper right hand corner
    status: PropTypes.string,

    // whether or not the progress bar is disabled
    disabled: PropTypes.bool,

    // whether or not the progress bar has a darker background bar
    darkerBackground: PropTypes.bool,

    // whether or not the progress bar's progress shimmers
    shimmer: PropTypes.bool
};

ProgressBar.defaultProps = {
    error: false,
    type: 'default',
    className: '',
    disabled: false,
    darkerBackground: false,
    shimmer: false
};
