import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Body, Heading } from '../modules/Typography';
import Icon from '../modules/Icon';
import Modal from '../modules/Modal';

const HomeCard = (props) => {
    const [modalOpen, setModal] = useState(false);

    const handleModal = () => {
        setModal(!modalOpen);
    };

    return (
        <div className='Home__Card flex column align-center'>
            <div className='flex column align-center'>
                <Icon
                    disneyBigIcon
                    disney
                    className='Home__Card__icon'
                    name={props.icon}
                />
                <Heading number={4}>{props.title}</Heading>
                <Heading number={6}>{props.subtitle}</Heading>
            </div>
            <div onClick={handleModal} className='button flex align-baseline'>
                <Icon className='Home__Card__info link-text' name='info' />
                <Body className='Home__Card__text link-text' number={1}>
                    learn more
                </Body>
            </div>
            <Modal
                show={modalOpen}
                title={props.title}
                content={props.overlayContent}
                handleClose={handleModal}
            />
        </div>
    );
};

HomeCard.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    overlayContent: PropTypes.string
};

export default HomeCard;
