import React from 'react';
import WidthContextProvider from '../contexts/WidthContextProvider';
import UserContextProvider from '../contexts/UserContextProvider';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../styles/main.scss';

import GraphiqlEditor from './GraphiqlEditor';
import Home from './pages/Home';
import StartApplication from './pages/StartApplication';
import Application from './pages/Application';
import SeasonOne from './pages/SeasonOne';
import SeasonTwo from './pages/SeasonTwo';
import Faqs from './pages/Faqs';
import Login from './pages/Login';
import Layout from './layout/Layout';
import ResetPassword from './login/ResetPassword';
import CtdiPrograms from './pages/CtdiPrograms';
import News from './pages/News';

const routes = [
    { path: '/', component: Home },
    { path: '/faq', component: Faqs },
    { path: '/2020directors', component: SeasonOne },
    { path: '/2021directors', component: SeasonTwo },
    { path: '/login', component: Login },
    { path: '/account', component: StartApplication },
    { path: '/application', application: true, component: Application },
    { path: '/reset-password/:token', component: ResetPassword },
    { path: '/ctdi-programs', component: CtdiPrograms },
    { path: '/news', component: News }
];

function App () {
    return (
        <BrowserRouter>
            <WidthContextProvider>
                <UserContextProvider>
                    <Routes>
                        <Route path='/graphiql' element={<GraphiqlEditor />} />
                        {routes.map((route, key) => {
                            const { path, component: Component, ...props } = route;

                            return (
                                <Route
                                    key={key}
                                    path={path}
                                    element={
                                        <Layout {...props}>
                                            <Component />
                                        </Layout>
                                    }
                                />
                            );
                        })}
                        <Route path='*' element={<Navigate to='/' />} />
                    </Routes>
                </UserContextProvider>
            </WidthContextProvider>
        </BrowserRouter>
    );
}

export default App;
