import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ADD_EMAIL } from '../../queries';
import { Row, Col } from '../modules/Grid';
import { Body, Heading, Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';

const EmailSignup = (props) => {
    const [addEmail] = useMutation(ADD_EMAIL);
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);
    const currentDate = new Date();

    const handleAdd = async () => {
        if (email === '') {
            setErrors([...errors, 'email']);
            return;
        }
        if (!errors.includes('email')) {
            await addEmail({ variables: { email } });
            props.handleEmailSent();
        }
    };

    const testEmail = (e) => {
        const { value } = e.target;
        const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setErrors(errors.filter((error) => error !== 'email'));
        } else {
            setErrors([...errors, 'email']);
        }
    };

    return (
        <Fragment>
            <Row>
                <Col className='flex column'>
                    <Heading number={3} id='emailSignUpForm'>
                        Email Sign Up
                    </Heading>
                    <Body>
                        {/* Applications for Season Three are now open! */}
                    </Body>
                    {/* <Body>
                        Applications for {currentDate.getFullYear()} will open later this
                        year
                    </Body> */}
                </Col>
            </Row>
            <Row>
                <Col lg={12} xl={6}>
                    <TextField
                        class='__formFieldText'
                        placeholder='Email Address'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={testEmail}
                        error={errors.includes('email')}
                        errorMessage='Please enter a valid email'
                    />
                </Col>
                <Col lg={12} xl={6}>
                    <Button type='primary' block onClick={handleAdd}>
                        Sign Up
                    </Button>
                </Col>
            </Row>
            <Row className='Footer__fine-print'>
                <Col lg={12}>
                    <Caption>
                        By creating an account, I agree to the{' '}
                        <a
                            href='https://disneytermsofuse.com/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Terms of Use
                        </a>{' '}
                        and acknowledge that I have read the{' '}
                        <a
                            href='https://privacy.thewaltdisneycompany.com/en/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a
                            href='https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-california-privacy-rights/#heading1'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            California Privacy Notice
                        </a>
                        .
                    </Caption>
                </Col>
            </Row>
        </Fragment>
    );
};

EmailSignup.propTypes = {
    handleEmailSent: PropTypes.func
};

export default EmailSignup;
