import React, { useContext, useState, useEffect } from 'react';
import EmploymentForm from './EmploymentForm';
import { ApplicationContext } from '../pages/Application';

const EqualEmployment = props => {
    const { application = {}, setEeoValid, refetch } = useContext(ApplicationContext);
    const { applicants = [] } = application;
    const [validApplicants, setValidApplicants] = useState([]);

    useEffect(() => {
        refetch();
    }, []);

    const handleValid = (idx, isApplicantValid) => {
        const validApps = [...validApplicants];
        validApps[idx] = isApplicantValid;
        setValidApplicants(validApps);
        setEeoValid(!validApps.includes(false));
    };

    return (
        <div className='EqualEmployment'>
            {applicants.map((applicant, idx) => (
                <>
                    <EmploymentForm
                        key={`${applicant.id}-${idx}`}
                        applicant={applicant}
                        idx={idx}
                        handleValid={handleValid}
                    />
                    <hr className='Application__divider' />
                </>
            ))}
        </div>
    );
};

export default EqualEmployment;
