import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../pages/Application';
import Button from '../modules/Button';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';

const ReviewEmployment = props => {
    const { races = [], ethnicities = [], genders = [], veterans = [], disabilities = [], employees = [], setStep } = useContext(ApplicationContext);

    return (
        <>
            <hr className='Application__divider' />
            <div>
                <Row>
                    <Col xs={6} xsOffset={1} lgOffset={2} className='flex column'>
                        <Heading block number={3} className='Application__Review__heading'>Equal Employment Information</Heading>
                    </Col>
                    <Col xs={5} md={2} lg={1} mdOffset={2} lgOffset={1}>
                        <Button color='info' type='tertiary' icon='edit-page' disney onClick={() => setStep(3)}>Edit</Button>
                    </Col>
                </Row>
                {props.applicants?.map(applicant => {
                    const gender = genders.find(g => g.value === applicant.eeo?.gender);
                    const ethnicity = ethnicities.find(e => e.value === applicant.eeo?.ethnicity);
                    const race = races.find(r => r.value === applicant.eeo?.race);
                    const veteran = veterans.find(v => v.value === applicant.eeo?.veteran);
                    const disability = disabilities.find(d => d.value === applicant.eeo?.disability);
                    const employee = employees.find(em => em.value === applicant.eeo?.employee);
                    const genderName = gender ? gender.text : 'N/A';
                    const ethnicityName = ethnicity ? ethnicity.text : 'N/A';
                    const raceName = race ? race.text : 'N/A';
                    const veteranName = veteran ? veteran.text : 'N/A';
                    const disabilityName = disability ? disability.text : 'N/A';
                    const employeeName = employee ? employee.text : 'N/A';

                    return (
                        <React.Fragment key={`${applicant.id}`}>
                            <Row className='Application__Review__row'>
                                <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                                    <Heading block number={4}>{`${applicant.firstName || 'N/A'} ${applicant.lastName || 'N/A'}`}</Heading>
                                </Col>
                            </Row>
                            <Row className='Application__Review__row'>
                                <Col xs={8} lg={4} xsOffset={1} lgOffset={2}>
                                    <Heading block number={4}>Opt Out?</Heading>
                                    <Body block>{applicant.eeo?.optOut ? 'Yes' : 'No'}</Body>
                                </Col>
                                <Col xs={8} lg={4} xsOffset={1} lgOffset={1}>
                                    <Heading block number={4}>Are you Hispanic or Latino?</Heading>
                                    <Body block>{ethnicityName}</Body>
                                </Col>
                            </Row>
                            <Row className='Application__Review__row'>
                                <Col xs={11} lg={4} xsOffset={1} lgOffset={2}>
                                    <Heading block number={4}>Gender</Heading>
                                    <Body block>{genderName}</Body>
                                </Col>
                                <Col xs={11} lg={4} xsOffset={1} lgOffset={1}>
                                    <Heading block number={4}>Veteran Status</Heading>
                                    <Body block>{veteranName}</Body>
                                </Col>
                            </Row>
                            <Row className='Application__Review__row'>
                                <Col xs={11} lg={4} xsOffset={1} lgOffset={2}>
                                    <Heading block number={4}>Race</Heading>
                                    <Body block>{raceName}</Body>
                                </Col>
                                <Col xs={11} lg={4} xsOffset={1} lgOffset={1}>
                                    <Heading block number={4}>Disability Status</Heading>
                                    <Body block>{disabilityName}</Body>
                                </Col>
                            </Row>
                            <Row className='Application__Review__row'>
                                <Col xs={11} lg={4} xsOffset={1} lgOffset={2}>
                                    <Heading block number={4}>Disney or Affiliate Employee?</Heading>
                                    <Body block>{employeeName}</Body>
                                </Col>
                            </Row>
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

ReviewEmployment.propTypes = {
    applicants: PropTypes.array
};

export default ReviewEmployment;
