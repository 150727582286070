import React, { useContext } from 'react';
import { Tab } from 'react-bootstrap';
import { FaqContext } from '../pages/Faqs';
import { Col } from '../modules/Grid';
import Faq from './Faq';

const FaqList = props => {
    const { faqs } = useContext(FaqContext);

    return (
        <Col className='Faqs__List' xs={12} lg={10}>
            <Tab.Content>
                {faqs.map(group => (
                    <Tab.Pane eventKey={group.section} key={group.section}>
                        {group.questions.map((question, index) => (
                            <Faq
                                key={index}
                                title={question.q}
                                description={question.a}
                                id={index}
                            />
                        ))}
                    </Tab.Pane>
                ))}
            </Tab.Content>
        </Col>
    );
};

export default FaqList;
