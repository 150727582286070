import annMarie from '../../images/annMarie.jpg';
import aqsa from '../../images/aqsaAltaf.jpg';
import hao from '../../images/haoZheng.jpg';
import jessica from '../../images/jessicaMendez.jpg';
import moxie from '../../images/moxie_peng.jpg';
import stefanie from '../../images/abelHorowitz.jpeg';
import americaneid from '../../images/AE.png';
import lbt from '../../images/letsBeTigers.png';
import tlp from '../../images/TLP.png';
import dinner from '../../images/dinner_is_served.png';
import fangs from '../../images/GrowingFangs.png';
import tlotc from '../../images/tlotc.png';

const directors = [
    {
        name: 'Ann Marie Pace',
        description: 'Ann Marie Pace is a writer/director raised in Tennessee and based in Los Angeles. Growing up Mexican American and bisexual, she is drawn to stories of identity and discovering your inner truth when you don’t just fit one box. Raised in a family of anthropologists, Ann Marie puts an immense amount of care and consideration into being authentic to characters, their culture, and their truths. She tells stories from multicultural perspectives to help create bridges of understanding. She received her MFA in Directing from UCLA. Her thesis film BEYOND THE GATE, shot in the Brazilian Amazon, won 1st place in the DGA Student Film Awards Latino Category, Best Student Film at the Brazil International Film Festival, and was a Caucus Foundation Grant Winner.',
        logline: 'Val Garcia, a Mexican-American half human/half vampire teenager, struggles to fit in either world.',
        image: annMarie,
        poster: fangs,
        loglineName: 'Growing Fangs'
    },
    {
        name: 'Aqsa Altaf',
        description: 'Los Angeles based filmmaker Aqsa Altaf loves to capture moments that feel like they are stolen from everyday life with a cinematic edge. Being raised in Kuwait by Pakistani and Sri Lankan parents in a Muslim household, Aqsa is fascinated with telling stories that are diverse in nature and reflect on subject matters that are universal. <br/><br/> Aqsa graduated from USC School of Cinematic Arts. Her short film ONE SMALL STEP has won many awards and played at the American Pavilion at the Cannes Film Festival 2019. It was also shortlisted for BAFTA Student Film Awards. <br/><br/> She was nominated for the Young Director Award 2019 at Cannes Lions for her film which she made under the mentorship of Werner Herzog. She recently got selected as one of the directors for the Disney Launchpad under which she wrote and directed a short film that will be released on Disney+ later this year.<br/><br/>She is currently developing a TV series based on a proof-of-concept film she made as well as her first feature project.',
        logline: 'Ameena, a homesick Muslim Pakistani immigrant, wakes up on Eid to find out she has to go to school.',
        image: aqsa,
        poster: americaneid,
        loglineName: 'American Eid'
    },
    {
        name: 'Hao Zheng',
        description: 'Studied at Emerson College (Film Production) and AFI (Directing), Hao Zheng is a recent Student Academy Award winning director transitioned from being a professional actor in popular Chinese films and TV shows. With short films getting international recognition including Show Me Shorts New Zealand (Best International Film Award), AT&T Film Award, Nowness Awards, Short Shorts Tokyo, Aspen Short Fest among more than 100 film festivals, he is developing a range of multi-cultural projects in both China and the US.<br/><br/>Recently, Hao became a 2021 Forbes 30 Under 30 Honoree (Hollywood & Entertainment), and was selected as a 2020 Disney Launchpad Director, 2020 HBOAccess Directing Fellow and a 2019 First International Film Festival Directing Lab Fellow.',
        logline: 'A Chinese student uncovers his true identity when trying out for a leadership role at a U.S. school.',
        image: hao,
        poster: dinner,
        loglineName: 'Dinner is served'
    },
    {
        name: 'Jessica Mendez Siqueiros',
        description: 'Jessica Mendez Siqueiros is a Chicana writer/director of mixed indigenous Sonoran and European ancestry seeking to normalize complex & authentic narratives about the Southwest Mexican-American community through film. Her short films have screened at over 75 festivals, including Palm Springs, Seattle, Cleveland, Hamptons, and the Los Angeles Latino Film Festival. They have garnered an Audience Pick from Palm Springs ShortFest, the KidsEye Grand Prize for Best Live Action Short from Flickers Rhode Island Film Festival, Best Narrative Short (Comedy) from Cinequest, among other awards. Selected as a director mentee in the AT&T Hello Lab Mentorship Program, she directed ADELINE, THE GREAT, under the guidance of Lena Waithe and Rishi Rajani. Her first feature film, REFORMA, which delves into the colorful, complicated world of a young Latina woman in La Reforma (the Tucson, AZ projects) in the 1960’s. Reforma is in development with the support of the Sundance Feature Film Program, and the recipient of the 2021 Latinx Fellowship. She will also participate in the second class of Powderkeg Fuse helmed by Paul Feig & Lisa Fischer.',
        logline: 'A lonely Mexican-American woman unknowingly summons a dark and ancient creature.',
        image: jessica,
        poster: tlotc,
        loglineName: 'The last of the chupacabras'
    },
    {
        name: 'Moxie Peng',
        description: 'Moxie Peng is a writer and director from Hunan, China. Moxie’s subjects are often the working class, immigrant ethnic minorities, and folks in the queer community, and they often address intersectionality among them. They started filmmaking in Beijing and now live in New York City and Los Angeles. Their shorts have been screened at NewFest, Asian American International Film Festival, Cleveland International Film Festival, and more. Moxie recently graduated from the Filmmaking MFA program at NYU class of 2020 and is developing their debut feature.',
        logline: 'When Chinese kids Gabriel and Rob become friends, Rob\'s dad questions Gabriel\'s feminine behavior.',
        image: moxie,
        poster: tlp,
        loglineName: 'The little prince(ss)'
    },
    {
        name: 'Stefanie Abel Horowitz',
        description: 'Stefanie Abel Horowitz directed theater for a decade before pivoting to film. Her second short film, SOMETIMES, I THINK ABOUT DYING premiered at the 2019 Sundance Film Festival, traveled the world on the Sundance Shorts Tour, garnered Abel Horowitz the Future Filmmaker Award from Palm Springs International Film Festival, and was shortlisted for an Academy Award. She is an alum of the 2019 New Voices Sundance Lab as well as a member of Lincoln Center Directors Lab. Her essay “He’s Your Destiny. Just Be Patient.” was published in <i>The New York Times</i> Modern Love 2019 column.',
        logline: 'Grieving for her mother, Avalon finds comfort when she\'s put in charge of a 4-year-old for a night.',
        image: stefanie,
        poster: lbt,
        loglineName: 'Let\'s be tigers'
    }
];

export default directors;
