import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';

const HomeStory = () => {
    return (
        <div className='Home__success' id='lookingFor'>
            <Container>
                <Heading center number={2}>
                    What is Disney looking for in a story or script?
                </Heading>
                <Row>
                    <Col xs={10} lg={5} xsOffset={1} className='spacing-sm'>
                        <Body>
                            For over one hundred (100) years, Disney has been home to some of the world’s most beloved characters and stories which stretch the boundaries of our imagination. Known for high-quality filmmaking, Disney holds a special place in the hearts of millions of people because our themes and characters are universal, relatable, and relevant to everyone. Our characters appeal to all families and are defined by courage, wonder, whimsy, wit, fun, hope, and authenticity.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            We welcome characters (including protagonists) of all backgrounds, ages, identities, and perspectives. We value stories that are deeply profound, meaningful, emotionally resonant, and authentic, which build a world or demonstrate a real sense of place, in genres that include fantasy, sci-fi, action and adventure, coming-of-age, comedy, or musical. We are looking for stories that don’t have gratuitous violence, profanity, or oversexualized characters, and have an optimistic ending.
                        </Body>
                        <br />
                        <br />
                    </Col>
                    <Col xs={10} lg={5} xsOffset={1} className='spacing-sm'>
                        <Body>
                            For tone and feel, please consider properties found on Disney+.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            We look forward to you bringing your vision to the story. As Kevin Feige, President of Marvel Studios, expressed about{' '}
                            <em>Black Panther</em>, “To me, the best thing a producer can do is find a person with something to say, who has a story to tell and can tell it in a way that the world responds to.”
                        </Body>
                        <br />
                        <br />
                        <Body>
                            All scripts submitted for Disney Launchpad Season Three (3) should be centered on this year’s theme of FORWARD, a future-looking meditation on hope, possibility, and change. In the spirit of short films, we encourage you to consider the production scope of your scripts as the budget for these shorts is limited. To be in line with the Launchpad program production budget, scripts should keep production locations contained and have minimal VFX. We plan for the films to be shot in Southern California. {' '}
                            <Link to='/faq'>For more information, please see the FAQ.</Link>
                        </Body>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomeStory;
