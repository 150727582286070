import React from 'react';
import { Container, Row, Col } from '../modules/Grid';
import homeConfig from './homeConfig';
import { Heading, Body } from '../modules/Typography';

const HomeSuccess = () => {
    return (
        <div className='Home__success' id='programDetails'>
            <Container>
                <Heading center number={2}>
                    DISNEY LAUNCHPAD: SHORTS INCUBATOR
                </Heading>
                <Heading center number={3}>
                    A live-action shorts program from The Walt Disney Studios.
                </Heading>
                <Row className='spacing-md'>
                    <Col xs={10} lg={5} xsOffset={1}>
                        <Heading number={3}>About the Program</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} lg={5} xsOffset={1} className='spacing-sm'>
                        <Body>
                            With a focus on building a more inclusive entertainment industry, Disney is looking for experienced writers and/or directors from underrepresented backgrounds or those with unique perspectives to produce a short film for the Disney Launchpad: Shorts Incubator program.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            As part of Disney Launchpad, writers and/or directors will be paired with Disney creative executives who will serve as mentors as they embark on a training program centered on creative collaboration within the Disney Studio system. Under the purview of the Disney Launchpad Producer, three (3) writers and/or directors will work to deliver three (3) live-action short films for possible exhibition on Disney+.
                        </Body>
                        <br />
                        <br />
                        <Body>
                            Select shorts may be considered for further feature-length or episodic development, based on the short’s quality, performance on the platform, future arcs, and other factors, though future development is not guaranteed.
                        </Body>
                    </Col>
                    <Col xs={10} lg={5} xsOffset={1} lgOffset={0} className='spacing-sm'>
                        <Body>
                            Launchpad is a minimum twelve (12) month program that will include the following (please note that timeframes and program parameters are subject to change):
                        </Body>
                        <br />
                        <br />
                        <ul>
                            {homeConfig.parameters.map((param) => (
                                <li className='padded' key={param}>
                                    {param}
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HomeSuccess;
