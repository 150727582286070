const uploadConfig = {
    scriptUpload:
    {
        title: 'Script',
        overlayContent: `<p>For more than 90 years, Disney has been home to some of the world’s most beloved characters and stories which stretch the boundaries of our imagination. Known for high-quality filmmaking, Disney holds a special place in the hearts of millions of people because our themes and characters are universal, relatable, and relevant to everyone. Our characters appeal to all families and are defined by courage, wonder, whimsy, wit, fun, hope, and authenticity.</p>
          <p>We welcome characters (including protagonists) of all backgrounds, ages, identities, and perspectives. We value stories that are deeply profound, meaningful, emotionally resonant, and authentic, which build a world or demonstrate a real sense of place, in genres that include fantasy, sci-fi, action, adventure, coming-of-age, comedy, or musical. We are looking for stories that don’t have gratuitous violence, profanity, or oversexualized characters.
          </p>
          <p>We look forward to you bringing your vision to the story. As Kevin Feige, President of Marvel Studios, expressed about <em>Black Panther,</em> “To me, the best thing a producer can do is find a person with something to say, who has a story to tell and can tell it in a way that the world responds to.”</p>
          <p>All scripts submitted for the Launchpad should be inspired by the theme of “connection.” How this theme comes through is to the work is at the discretion of the filmmaker. </p>
          <p>In the spirit of short films, we encourage you to consider the production scope of your scripts as the budget on these shorts is limited. In order to be in line with the Launchpad production budget, scripts should keep production locations contained and have minimal VFX. We plan for the films to be shot in Southern California. <a href="/faq">Please see the FAQ for more information</a>.</p>`

    },
    uploads: [
        {
            title: 'Resume',
            name: 'resume',
            description: 'A resume (one (1) page max) detailing your professional work and education. Applications submitted that do not satisfy this requirement will be disqualified.<br /><br />Required'
        },
        {
            title: 'Optional Professional Reference',
            name: 'reference',
            description: 'A letter of recommendation (one (1) page maximum) from a co-worker, mentor or supervisor that can attest to your professional character, personal demeanor and collaborative spirit. Optional.',
            isOptional: true
        }

    ],
    agreement: {
        title: 'Disney Launchpad Submission Agreement and Release',
        name: 'agreement',
        description: 'Please download and sign our release form, then upload the signed version.<br /><br />Required'
    }
};

export default uploadConfig;
