import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ADD_UPLOAD, REMOVE_UPLOAD, UPDATE_UPLOAD } from '../../queries';
import Select from '../modules/Select';
import TextField from '../modules/TextField';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Radio from '../modules/Radio';
import Modal from '../modules/Modal';
import { ApplicationContext } from '../pages/Application';
import UploadFile from './UploadFile';

const ScriptUpload = (props) => {
    const { genres, application, showErrors } = useContext(ApplicationContext);
    const [addUpload] = useMutation(ADD_UPLOAD);
    const [updateUpload] = useMutation(UPDATE_UPLOAD);
    const [removeUpload] = useMutation(REMOVE_UPLOAD);
    const [modalOpen, setModal] = useState(false);
    const [genre, setGenre] = useState('');
    const [writtenBy, setWrittenBy] = useState('me');
    const [fileLoading, setLoading] = useState(false);
    const [filename, setFilename] = useState('');
    const [uploadFile, setUploadFile] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        const uploadFile = application.uploads.find(
            (upload) => upload.name === 'script'
        );
        if (uploadFile) {
            setFilename(uploadFile.filename);
            setUploadFile(uploadFile);
            if (uploadFile.params) {
                setWrittenBy(
                    uploadFile.params.find((param) => param.key === 'writtenBy')
                        ? uploadFile.params.find((param) => param.key === 'writtenBy').value
                        : 'me'
                );
                const genre = uploadFile.params.find((param) => param.key === 'genre')
                    ? uploadFile.params.find((param) => param.key === 'genre').value
                    : '';
                setGenre(genre);
                if (!genre) {
                    setErrors((errors) => [...errors, 'genre']);
                } else {
                    setErrors((errors) => errors.filter((error) => error !== 'genre'));
                }
            }
        } else {
            setErrors(['file', 'genre']);
        }
    }, [application]);

    useEffect(() => {
        props.handleValid('script', !errors.length);
    }, [errors]);

    const handleModal = () => {
        setModal(!modalOpen);
    };

    const handleSelect = ({ value }) => {
        setGenre(value);
        if (uploadFile.id) {
            updateUpload({
                variables: {
                    id: uploadFile.id,
                    params: [
                        { key: 'genre', value: value },
                        { key: 'writtenBy', value: writtenBy }
                    ]
                }
            });
        }
        setErrors((errors) => errors.filter((error) => error !== 'genre'));
    };

    const handleRadio = (value) => {
        setWrittenBy(value);
        if (uploadFile.id) {
            updateUpload({
                variables: {
                    id: uploadFile.id,
                    params: [
                        { key: 'genre', value: genre },
                        { key: 'writtenBy', value: value }
                    ]
                }
            });
        }
    };

    const handleUpload = async ({
        target: {
            validity,
            files: [file]
        }
    }) => {
        if (validity.valid) {
            setErrors((errors) => errors.filter((error) => error !== 'file'));
            try {
                setLoading(true);
                setFilename(file.name);
                const response = await addUpload({
                    variables: {
                        name: 'script',
                        file,
                        params: [
                            { key: 'genre', value: genre },
                            { key: 'writtenBy', value: writtenBy }
                        ]
                    }
                });
                const { filename, id } = response.data.addUpload;
                setUploadFile({ name: filename, id });
                setFilename(filename);
                setLoading(false);
            } catch (err) {
                setErrors([...errors, 'file']);
            }
        }
    };

    const handleDelete = (id) => {
        removeUpload({
            variables: {
                id
            }
        });
        setErrors([...errors, 'file']);
        setFilename('');
        setUploadFile({});
    };

    return (
        <div className='Upload'>
            <Container>
                <hr className='Application__divider' />
                <Row>
                    <Col xs={10} md={5} lg={4} xsOffset={1} lgOffset={2}>
                        <Heading block number={2}>
                            Script
                        </Heading>
                        <Body block>
                            An original, narrative script for a short film up to ten (10)
                            pages which aligns tonally with the Disney brand that is inspired
                            by the theme of "Forward!” Adaptations, biopics, and screenplays
                            based on existing content are not eligible. Scripts based on
                            Disney intellectual property are not allowed. This script will be
                            the basis of the story you will develop and produce throughout
                            Launchpad if selected.
                            <span className='Upload__link' onClick={handleModal}>
                                {' '}
                                Learn more about what we’re looking for in a story.
                            </span>
                        </Body>
                        <Body block>
                            All scripts must be submitted via PDF in a format consistent with
                            industry standard (Courier, 12 pt. type, and standard margins).
                            All scripts must have a limit of fifteen (15) pages, not including
                            the cover page. Applications submitted with scripts that do not
                            satisfy these requirements will be disqualified.
                        </Body>
                        <Body block>Required</Body>
                        <Body block className='Upload__bold'>
                            Who wrote the script?
                        </Body>
                        <div className='Upload__radio__block'>
                            <Radio
                                inline
                                padded={false}
                                value='me'
                                handleChange={handleRadio}
                                selected={writtenBy === 'me'}
                            />
                            <Body>The script was written by me.</Body>
                        </div>
                        <div className='Upload__radio__block'>
                            <Radio
                                inline
                                padded={false}
                                value='other'
                                handleChange={handleRadio}
                                selected={writtenBy === 'other'}
                            />
                            <Body>The script was written by another writer and me.</Body>
                        </div>
                        <div className='Upload__radio__block'>
                            <Radio
                                inline
                                padded={false}
                                value='team'
                                handleChange={handleRadio}
                                selected={writtenBy === 'team'}
                            />
                            <Body>
                                The script was written by another writer or writing team of two.
                            </Body>
                        </div>
                    </Col>
                    <Col xs={10} md={4} lg={3} xsOffset={1}>
                        <UploadFile
                            handleUpload={handleUpload}
                            fileTypes={['.pdf']}
                            file={uploadFile || {}}
                            handleDelete={handleDelete}
                            loading={fileLoading}
                            filename={filename}
                            error={showErrors && errors.includes('file')}
                            errorMessage='Please upload a file.'
                        />
                        <div className='selector__parent'>
                            <Select
                                label='Genre:'
                                options={genres}
                                selectedValue={genre}
                                onChange={handleSelect}
                                placeholder='Please select...'
                                error={showErrors && errors.includes('genre')}
                                errorMessage='Please select a genre.'
                                disabled={fileLoading}
                            />
                        </div>
                        <TextField hidden className='visually-hidden__tabs' />
                    </Col>
                </Row>
            </Container>
            <Modal
                show={modalOpen}
                content={props.upload.overlayContent}
                handleClose={handleModal}
            />
        </div>
    );
};
ScriptUpload.propTypes = {
    upload: PropTypes.object,
    handleValid: PropTypes.func
};

export default ScriptUpload;
