import React, { Fragment, useContext, useEffect, useState } from 'react';
import { WidthContext } from '../../contexts/WidthContextProvider';
import heroBackground from '../../images/season2_hero.png';
import heroLogo from '../../images/launchpad_logo.png';
import { Heading, Body } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';

const HomeHero = (props) => {
    const { isMobile } = useContext(WidthContext);
    const { applicationsOpen } = useContext(UserContext);
    const isOptedOut = window.OnetrustActiveGroups?.includes(',C0002,');

    const scrollWithOffset = (el, offset) => {
        let elementPosition = el.offsetTop - offset;
        let node = el;
        while (node.offsetParent !== document.body) {
            node = node.offsetParent;
            elementPosition += node.offsetTop;
        }
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <Fragment>
            <div
                className='Home__hero__container'
                style={{ backgroundImage: `url("${heroBackground}")` }}
            >
                {/* <img className='Home__hero__header' src={heroImageReel} /> */}
            </div>

            <div className='Home__hero__text__container'>
                <img
                    className='Home__hero__container__logo'
                    src={heroLogo}
                    alt='Disney Launchpad Logo'
                />
                <Heading className='Home__hero__text' number={2}>
                    The Application window for Launchpad Season Three is now closed. Thank you all for your submissions!
                </Heading>
                <br />
                <br />
                <Row className='flex center padded'>
                    <Col xs={12} md={6} lg={4}>
                        {applicationsOpen && <Button type='primary' size='large' block link='/login'>Apply Now</Button>}
                    </Col>
                </Row>
            </div>
            <Container className='Home__hero__description'>
                <Body>With our new theme FORWARD, the Disney Launchpad: Shorts Incubator returns for a third (3) season focused on identifying, developing, and showcasing the Disney storytellers of tomorrow. In this season, we will be producing three (3) shorts. If you have a passion for storytelling and a unique perspective, we want to hear from you!</Body>
            </Container>
            <Container>
                <Heading
                    className='Directors__heading underline spacing-md'
                    center
                    number={1}
                >
                    Season Two Trailer
                </Heading>
                {isOptedOut ? (
                    <Row className='flex center padded'>
                        <iframe
                            width='859'
                            height='488'
                            src='https://www.youtube.com/embed/tT8uN_80YoI'
                            title='YouTube video player'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                    </Row>
                )
                    : (
                        <div className='flex center'>
                            <a href='https://www.youtube.com/watch?v=tT8uN_80YoI' target='blank'>Watch the trailer for season two on YouTube</a>
                        </div>
                    )}
            </Container>
        </Fragment>
    );
};

export default HomeHero;
