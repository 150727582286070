import React, { useContext } from 'react';
import { Container, Row, Col } from '../modules/Grid';
import { HashLink } from 'react-router-hash-link';
import { Heading } from '../modules/Typography';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';

const HomeApplication = (props) => {
    const scrollWithOffset = (el, offset) => {
        let elementPosition = el.offsetTop - offset;
        let node = el;
        while (node.offsetParent !== document.body) {
            node = node.offsetParent;
            elementPosition += node.offsetTop;
        }
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: 'smooth'
        });
    };
    const { applicationsOpen } = useContext(UserContext);

    return (
        <div className='Home__theme spacing-md'>
            <Container>
                <Row xsOffset={1} className='flex align-center center'>
                    <Col xs={10} lg={open ? 8 : 10} className='Home__theme__text'>
                        <Icon name='clock' className='Home__theme__icon' />
                        {applicationsOpen ? (
                            <Heading number={5}>
                                Sign up to learn more about Disney Launchpad updates!
                            </Heading>
                        ) : (
                            <Heading number={5}>
                                Sign up to hear when Disney Launchpad applications open up next!
                            </Heading>
                        )}
                    </Col>
                    {!applicationsOpen ? (
                        <Col className='padded' xs={8} md={6} lg={3} lgOffset={0}>
                            <HashLink to='/#emailSignUpForm'>
                                <Button type='secondary' size='large' block>
                                    Sign Up
                                </Button>
                            </HashLink>
                        </Col>
                    ) : (
                        <Col className='padded' xs={8} md={6} lg={3} lgOffset={0}>
                            <Button
                                className='Home__apply__login'
                                link='/login'
                                type='secondary'
                                size='large'
                                block
                            >
                                Apply Now
                            </Button>
                        </Col>
                    )}
                </Row>
            </Container>
        </div>
    );
};

export default HomeApplication;
