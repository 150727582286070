import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_APPLICANT } from '../../queries';
import { ApplicationContext } from '../pages/Application';
import Checkbox from '../modules/Checkbox';
import Radio from '../modules/Radio';
import { Heading, Body, Caption } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';

const EmploymentForm = props => {
    const { showErrors, races, ethnicities, genders, veterans, disabilities, employees } = useContext(ApplicationContext);
    const [updateEeo] = useMutation(UPDATE_APPLICANT);
    const [errors, setErrors] = useState([]);
    const [optOut, setOptOut] = useState(null);
    const [gender, setGender] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [race, setRace] = useState('');
    const [veteran, setVeteran] = useState('');
    const [disability, setDisability] = useState('');
    const [employee, setEmployee] = useState('');

    const updateErrors = () => {
        const errors = [];
        if (!optOut) {
            if (!gender) {
                errors.push('gender');
            }
            if (!race) {
                errors.push('race');
            }
            if (!ethnicity) {
                errors.push('ethnicity');
            }
            if (!veteran) {
                errors.push('veteran');
            }
            if (!disability) {
                errors.push('disability');
            }
            if (!employee) {
                errors.push('employee');
            }
        }
        setErrors(errors);
    };

    useEffect(() => {
        const { optOut, gender, race, ethnicity, veteran, disability, employee } = props.applicant.eeo;
        setOptOut(optOut || false);
        setGender(gender);
        setRace(race);
        setEthnicity(ethnicity);
        setVeteran(veteran);
        setDisability(disability);
        setEmployee(employee);
        updateErrors();
    }, [props.applicant]);

    useEffect(() => {
        props.handleValid(props.idx, !errors.length);
    }, [errors]);

    useEffect(() => {
        if (optOut !== null) {
            updateEeo({
                variables: {
                    id: props.applicant.id,
                    applicant: {
                        eeo: {
                            optOut,
                            race,
                            gender,
                            ethnicity,
                            veteran,
                            disability,
                            employee
                        }
                    }
                }
            });
            updateErrors();
        }
    }, [optOut, race, gender, ethnicity, veteran, disability, employee]);

    const handleCheck = async ({ value, checked }) => {
        setOptOut(checked);
        if (checked) {
            setRace('');
            setGender('');
            setEthnicity('');
            setVeteran('');
            setDisability('');
            setEmployee('');
        }
    };

    const handleRace = (value) => {
        if (!optOut) {
            setRace(value);
        }
    };
    const handleGender = (value) => {
        if (!optOut) {
            setGender(value);
        }
    };
    const handleEthnicity = (value) => {
        if (!optOut) {
            setEthnicity(value);
        }
    };
    const handleVeteran = (value) => {
        if (!optOut) {
            setVeteran(value);
        }
    };
    const handleDisability = (value) => {
        if (!optOut) {
            setDisability(value);
        }
    };
    const handleEmployee = (value) => {
        if (!optOut) {
            setEmployee(value);
        }
    };

    return (
        <div className='Legal'>
            <Row>
                <Col xs={12} sm={10} lg={8} smOffset={1} lgOffset={2}>
                    <div>
                        <Heading number={2}>Equal Employment Data Collection ({`${props.applicant.firstName} ${props.applicant.lastName}`})</Heading>
                        <Body block>It is the policy of the Producer to provide equal opportunity for all crew members
                            and applicants for employment without regard to race, religion, color, sex, sexual
                            orientation, gender identity, national origin, age, marital status, covered veteran
                            status, mental or physical disability, pregnancy, or any other basis prohibited by
                            state or federal law.
                        </Body>
                        <Body block>
                            The answers you provide will be kept confidential and used solely for analytical and reporting requirement purposes. This information will not be used as a basis for any hiring, promotion, or other employment-related decisions by The Walt Disney Company or its subsidiaries and affiliated companies.
                        </Body>
                        <Body block>To help the Producer comply with federal/state equal employment opportunity record
                            keeping, reporting and other legal requirements, please fill out the following information:
                        </Body>
                        <Checkbox
                            className='spacing-sm'
                            label='I choose not to disclose my EEO information'
                            value='optOut'
                            checked={optOut}
                            handleCheck={handleCheck}
                        />
                        <div className={`Legal__eeoDetails ${optOut ? 'disabled' : ''}`}>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Gender</Body>
                                {showErrors && errors.includes('gender') && <Caption error className='Legal__error'>Please select a gender.</Caption>}
                            </div>
                            <Row>
                                <Col xs={3} xsOffset={1}>
                                    <Radio
                                        groupName='gender'
                                        name={genders[0].text}
                                        value={genders[0].value}
                                        selected={gender === genders[0].value}
                                        handleChange={handleGender}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Radio
                                        groupName='gender'
                                        name={genders[1].text}
                                        value={genders[1].value}
                                        selected={gender === genders[1].value}
                                        handleChange={handleGender}
                                    />
                                </Col>
                                <Col xs={3}>
                                    <Radio
                                        groupName='gender'
                                        name={genders[2].text}
                                        value={genders[2].value}
                                        selected={gender === genders[2].value}
                                        handleChange={handleGender}
                                    />
                                </Col>
                            </Row>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Ethnicity
                                    <i> (Select only one category that most closely represents
                                        your ethnic group):
                                    </i>
                                </Body>
                                {showErrors && errors.includes('ethnicity') && <Caption error className='Legal__error'>Please select an ethnicity.</Caption>}
                            </div>
                            <Row className='Legal__label'>
                                <Col xs={6} xsOffset={1}>
                                    <Body number={2}>Are you Hispanic or Latino?</Body>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} xsOffset={1}>

                                    <Radio
                                        groupName='ethnicity'
                                        name={ethnicities[0].text}
                                        value={ethnicities[0].value}
                                        selected={ethnicity === ethnicities[0].value}
                                        handleChange={handleEthnicity}
                                    />
                                </Col>
                                <Col xs={5}>
                                    <Radio
                                        groupName='ethnicity'
                                        name={ethnicities[1].text}
                                        value={ethnicities[1].value}
                                        selected={ethnicity === ethnicities[1].value}
                                        handleChange={handleEthnicity}
                                    />
                                </Col>
                            </Row>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Race
                                    <i> (Select the category that most closely represents your ethnic group):</i>
                                </Body>
                                {showErrors && errors.includes('race') && <Caption error className='Legal__error'>Please select a race.</Caption>}
                            </div>
                            <Row>
                                <Col xs={11} xsOffset={1}>
                                    {races.map(r => (
                                        <Radio
                                            key={r.value}
                                            groupName='race'
                                            name={r.text}
                                            value={r.value}
                                            selected={race === r.value}
                                            handleChange={handleRace}
                                        />
                                    ))}

                                </Col>
                            </Row>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Are you a veteran?</Body>
                                {showErrors && errors.includes('veteran') && <Caption error className='Legal__error'>Please select your status.</Caption>}
                            </div>
                            <Row>
                                <Col xs={8} xsOffset={1}>
                                    {veterans.map(v => (
                                        <Radio
                                            key={v.value}
                                            groupName='veteran'
                                            name={v.text}
                                            value={v.value}
                                            selected={veteran === v.value}
                                            handleChange={handleVeteran}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Disability Status</Body>
                                {showErrors && errors.includes('disability') && <Caption error className='Legal__error'>Please select your status.</Caption>}
                            </div>
                            <Row>
                                <Col xs={8} xsOffset={1}>
                                    {disabilities.map(d => (
                                        <Radio
                                            key={d.value}
                                            groupName='disability'
                                            name={d.text}
                                            value={d.value}
                                            selected={disability === d.value}
                                            handleChange={handleDisability}
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <div className='relative'>
                                <Body block className='spacing-sm'>Are you currently employed by The Walt Disney Company or any of its affiliated companies?</Body>
                                {showErrors && errors.includes('employee') && <Caption error className='Legal__error'>Please select your status.</Caption>}
                            </div>
                            <Row>
                                <Col xs={3} xsOffset={1}>
                                    {employees.map(em => (
                                        <Radio
                                            key={em.value}
                                            groupName='employee'
                                            name={em.text}
                                            value={em.value}
                                            selected={employee === em.value}
                                            handleChange={handleEmployee}
                                        />
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

EmploymentForm.propTypes = {
    applicant: PropTypes.object,
    handleValid: PropTypes.func,
    idx: PropTypes.number
};

export default EmploymentForm;
