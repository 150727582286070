import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../../images/launchpad_logo.png';
import MobileMenu from './MobileMenu';
import { UserContext } from '../../contexts/UserContextProvider';
import MenuSurface from '../modules/MenuSurface';
import MenuSurfaceOption from '../modules/MenuSurfaceOption';

const TopNav = (props) => {
    const location = useLocation();
    const [isTop, setTop] = useState(true);
    const { mobileMenuOpen, setMobileMenuOpen } = useContext(UserContext);
    const solidNavPages = [
        '/2020directors',
        '/2021directors',
        '/enter',
        '/login',
        '/application',
        '/reset-password',
        '/news',
        '/ctdi-programs',
        '/faq'
    ];

    const showNav = location.pathname === '/2020directors';
    const isSeasonTwo = location.pathname === '/2021directors';
    const solidHeader = solidNavPages.includes(location.pathname);
    const scrollWithOffset = (el, offset) => {
        const elementPosition = el.offsetTop - offset;
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: 'smooth'
        });
    };

    const listenScrollEvent = () => {
        const top =
      window.innerWidth >= 767.98
          ? window.scrollY + 106 < 120
          : window.scrollY < 50;
        setTop((isTop) => top);
    };

    const toggleMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    useEffect(() => {
        function refreshPage () {
            window.scrollTo(0, 0);
            window.location.reload();
        }

        const timeoutId = setTimeout(() => {
            window.addEventListener('OneTrustGroupsUpdated', refreshPage);
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('OneTrustGroupsUpdated', refreshPage);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('scroll', listenScrollEvent);
        return () => {
            document.removeEventListener('scroll', listenScrollEvent);
        };
    }, []);

    const { applicationsOpen } = useContext(UserContext);

    return (
        <div id='top' className={`header ${solidHeader ? 'solid' : ''}`}>
            <Navbar
                variant='dark'
                fixed='top'
                className={showNav || !isTop ? 'nav-scrolled' : ''}
            >
                <div className='nav-container flex'>
                    <Link to='/#top' className='header-logo'>
                        <Navbar.Brand>
                            <img
                                src={logo}
                                className='TopNav__logo'
                                alt='Disney Launchpad Logo'
                            />
                        </Navbar.Brand>
                    </Link>
                    <Nav>
                        <Link
                            to='/#programDetails'
                            scroll={(el) => scrollWithOffset(el, 60)}
                        >
                            Program Details
                        </Link>
                        <Link to='/#howToApply' scroll={(el) => scrollWithOffset(el, 60)}>
                            How To Apply
                        </Link>
                        <Link to='/#lookingFor' scroll={(el) => scrollWithOffset(el, 60)}>
                            What We're Looking For
                        </Link>
                        <Link to='/#timeline' scroll={(el) => scrollWithOffset(el, 60)}>
                            Timeline
                        </Link>
                        <MenuSurface
                            scroll={(el) => scrollWithOffset(el, 60)}
                            className={
                                showNav || isSeasonTwo ? 'seasons pointer' : 'active pointer'
                            }
                            children
                            options={[
                                { text: 'Season One', link: '/2020directors' },
                                { text: 'Season Two', link: '/2021directors' }
                            ]}
                        >
                            Seasons
                        </MenuSurface>
                        <Link to='/ctdi-programs'>CTDI Programs</Link>
                        <Link to='/news'>News</Link>
                        <Link to='/faq'>FAQs</Link>
                        {/* {applicationsOpen && <Link to='/login'>Apply Now</Link>} */}
                    </Nav>
                </div>
                <div onClick={toggleMenu} className='burger-container'>
                    <MobileMenu />
                </div>
            </Navbar>
        </div>
    );
};

export default TopNav;
