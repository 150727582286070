import React from 'react';
import PropTypes from 'prop-types';

const Box = props => (
    <div
        className={[
            'Box',
            `Box--${props.type}`,
            `Box--border-${props.borderType}`,
            props.padded ? 'padded' : null,
            props.topPadded ? 'padded__top' : null,
            props.bottomPadded ? 'padded__bottom' : null,
            props.rounded ? 'rounded' : null,
            props.elevated > 0 ? `elevation-${props.elevated}` : null,
            props.className,
            props.overflow ? 'overflow' : null,
            props.autoHeight ? 'Box--auto-height' : null
        ].filter(i => i).join(' ')}
        style={props.style}
        onClick={props.onClick}
        id={props.id || null}
    >
        {props.children}
    </div>
);

export default Box;

Box.propTypes = {
    // style of the box
    type: PropTypes.oneOf(['outline', 'gray-filled', 'background', 'white']),

    // style of the border
    borderType: PropTypes.oneOf(['none', 'dashed', 'thin-dashed']),

    // whether or not the box is padded
    padded: PropTypes.bool,
    topPadded: PropTypes.bool,
    bottomPadded: PropTypes.bool,

    // whether or not the box is rounded in the corners
    rounded: PropTypes.bool,

    // box elevation level
    elevated: PropTypes.number,

    // className to pass down to tags
    className: PropTypes.string,

    // whether or not the box overflows
    overflow: PropTypes.bool,

    // whether or not the height is set to auto
    autoHeight: PropTypes.bool,

    // style to pass down to tag
    style: PropTypes.object,

    // onclick to pass on to parent tag
    onClick: PropTypes.func
};

Box.defaultProps = {
    type: 'outline',
    padded: true,
    topPadded: false,
    bottomPadded: false,
    rounded: true,
    elevated: 0,
    className: '',
    overflow: false,
    autoHeight: false,
    borderType: 'none'
};
