import React from 'react';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';

const GraphiqlEditor = () => {
    const token = localStorage.getItem('token');
    console.log('graphiql token: ', token);

    function sentHeaders () {
        if (!token || token === 'undefined') {
            return { 'Content-Type': 'application/json' };
        } else {
            return { 'Content-Type': 'application/json', 'X-API-KEY': token };
        }
    }

    function graphQLFetcher (graphQLParams) {
        return fetch('/api/graphql', {
            method: 'post',
            headers: sentHeaders(),
            body: JSON.stringify(graphQLParams)
        }).then(response => response.json());
    }

    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            <GraphiQL fetcher={graphQLFetcher} />
        </div>
    );
};

export default GraphiqlEditor;
