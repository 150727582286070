import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Row, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import { UserContext } from '../../contexts/UserContextProvider';

const ApplicantCriteria = (props) => {
    const scrollWithOffset = (el, offset) => {
        let elementPosition = el.offsetTop - offset;
        let node = el;
        while (node.offsetParent !== document.body) {
            node = node.offsetParent;
            elementPosition += node.offsetTop;
        }
        window.scroll({
            top: elementPosition,
            left: 0,
            behavior: 'smooth'
        });
    };
    const { applicationsOpen } = useContext(UserContext);
    return (
        <Fragment>
            <Col lg={8} lgOffset={2}>
                <Row className='spacing-lg Home__applicant__header'>
                    <Col xs={10} lg={12} xsOffset={1} lgOffset={0}>
                        <Heading number={3}>Applicant Criteria</Heading>
                    </Col>
                </Row>
                <Row className='Home__applicant__text'>
                    <Col xs={10} lg={12} xsOffset={1} lgOffset={0}>
                        <Body>
                            To qualify for Disney Launchpad, applicants must:
                        </Body>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} lg={12} xsOffset={1} lgOffset={0}>
                        <ul className='Home__criteria__list'>
                            <li className='Home__criteria__list__item'>
                                Be from an underrepresented background and/or have a unique perspective.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Have the unrestricted right to be employed in the United States throughout the duration of the Launchpad program and throughout the term of any additional post-term obligations in connection with the Launchpad program. (Disney will not sponsor work visas for individuals employed for Launchpad.)
                            </li>
                            <li className='Home__criteria__list__item'>
                                Be at least twenty-one (21) years of age as of December 1, 2024.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Have completed at least one (1) scripted, live-action narrative work (e.g., a short, feature film, webisode, or episodic program) of at least five (5) minutes in length within the past eight (8) years if applying as a director.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Demonstrate a shared history of co-directing, if applying as a directing team of two (2), which will be evaluated on a case-by-case basis.{' '}
                                <Link to='/faq'>Please see the FAQ for more information.</Link>
                            </li>
                            <li className='Home__criteria__list__item'>
                                Co-wrote the submitted script together, if applying as a writing team of two (2).
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row className='spacing-lg'>
                    <Col lg={12} className='Home__ineligible__text'>
                        <Body>
                            You are NOT eligible to apply either as a director, writer, or writer/director, if you’ve done any of the following:
                        </Body>
                        <br />
                        <br />
                        <ul className='Home__ineligible__list'>
                            <li className='Home__criteria__list__item'>
                                Directed a theatrically distributed, scripted, non-documentary feature film.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Directed two (2) or more scripted, non-documentary episodes of a network television, cable television, or premium streaming series.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Directed two (2) or more scripted, non-documentary films, distributed on network television, cable television, or premium streaming service.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Written a theatrically distributed, scripted, non-documentary feature film.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Written more than two (2) episodes of an aired and/or released network television, cable, or premium streaming series.
                            </li>
                            <li className='Home__criteria__list__item'>
                                Written a scripted, non-documentary film, distributed on network television, cable television, or a premium streaming service.
                            </li>
                        </ul>
                        <Body>
                            For additional questions about eligibility, <Link to='/faq'>please visit our FAQ page.</Link>
                        </Body>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} xsOffset={0}>
                <div className='Home__apply spacing-lg'>
                    <div className='Home__apply__title flex column align-center'>
                        <Heading number={3}>Where can I apply?</Heading>
                        {!applicationsOpen && (
                            <Body>
                                Applications for this season are currently closed.
                                <br />
                                Sign up to hear when applications open up next!
                            </Body>
                        )}
                    </div>
                    <Row className='Home__apply__container'>
                        {applicationsOpen ? (
                            <Col xs={10} lg={7} xsOffset={1}>
                                <Body className='Home__apply__text'>
                                    To apply,{' '}
                                    <HashLink
                                        className='email-signup-link'
                                        to='/login'
                                    >
                                        please click here.
                                    </HashLink>{' '}
                                    <br />
                                    To Sign up for Disney Launchpad updates,{' '}
                                    <HashLink
                                        className='email-signup-link'
                                        to='/#emailSignUpForm'
                                    >
                                        click here!
                                    </HashLink>{' '}
                                </Body>
                            </Col>
                        ) : (

                            <Col xs={10} lg={7} xsOffset={1}>
                                <Icon name='clock' />
                                <Body className='Home__apply__text'>
                                    {/* Our theme for Season 3 submissions will be shared once
                                    applications open.{' '} */}
                                    <HashLink
                                        className='email-signup-link'
                                        to='/#emailSignUpForm'
                                    >
                                        To Sign up for Disney Launchpad updates, click here!
                                    </HashLink>{' '}
                                </Body>
                            </Col>
                        )}
                        <Col
                            className='padded'
                            xs={8}
                            md={6}
                            lg={3}
                            xsOffset={2}
                            mdOffset={3}
                            lgOffset={0}
                        >
                            {applicationsOpen ? (
                                <Button type='primary' size='large' block link='/login'>
                                    Apply Now
                                </Button>
                            ) : (
                                <HashLink
                                    to='/#emailSignUpForm'
                                    scroll={(el) => scrollWithOffset(el, 60)}
                                >
                                    <Button type='primary' size='large' block>
                                        Sign Up
                                    </Button>
                                </HashLink>
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Fragment>
    );
};

export default ApplicantCriteria;
