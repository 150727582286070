import laurelsAspen from '../../images/season2_laurels_aspen_sf_2023.png';
import laurelsBentonville from '../../images/season2_laurels_bentonville_film_festival_2023.png';
import laurelsDeadcenter from '../../images/season2_laurels_deadcenter_film_festival_2023.png';
import laurelsFrameline47 from '../../images/season2_laurels_frameline_47_san_francisco_international_lgbtq+_ff_2023.png';
import laurelsHawaiiInternational from '../../images/season2_laurels_hawaii_international_film_festival_2023.png';
import laurelsLosAngelesAsianPacific from '../../images/season2_laurels_los_angeles_asian_pacific_film_festival_2023.png';
import laurelsNewFilmMakers from '../../images/season2_laurels_new_film_makers_los_angeles_2023.jpg';
import laurelsNewYorkLatinoFetivalUrbanWorld from '../../images/season2_laurels_new_york_latino_film_festiva_2023.png';
import laurelsOutfestLosAngeles from '../../images/season2_laurels_outfest_los_angeles_2023.png';
import laurelsPalmSpringsInternational from '../../images/season2_laurels_palm_springs_shortfest_2023.png';
import laurelsSonomaFilmFestival from '../../images/season2_laurels_sonoma_iff_2023_SIFF.png';
import laurelsImagineNative from '../../images/season2_laurels_imagine_native_film_media_arts_festival_2023.png';

const laurelsTwo = [
    {
        id: 'l04',
        imageAltText: 'NewFilmmakers Los Angeles',
        imageSrc: laurelsNewFilmMakers,
        targetUrl: 'https://filmfreeway.com/NFMLA'
    },
    {
        id: 'l11',
        imageAltText: 'Aspen Shortsfest',
        imageSrc: laurelsAspen,
        targetUrl: 'https://filmfreeway.com/AspenShortsfest'
    },
    {
        id: 'l12',
        imageAltText: "Hawai'i International Film Festival",
        imageSrc: laurelsHawaiiInternational,
        targetUrl: 'https://filmfreeway.com/HIFF'
    },
    {
        id: 'l02',
        imageAltText: 'New York Latino Film Festival',
        imageSrc: laurelsNewYorkLatinoFetivalUrbanWorld,
        targetUrl: 'https://filmfreeway.com/NewYorkLatinoFilmFestival'
    },
    {
        id: 'l13',
        imageAltText:
      'Frameline: The San Fransisco International LGBTQ+ Film Festival',
        imageSrc: laurelsFrameline47,
        targetUrl: 'https://filmfreeway.com/Frameline'
    },
    {
        id: 'l07',
        imageAltText: 'deadCenter Film Festival',
        imageSrc: laurelsDeadcenter,
        targetUrl: 'https://filmfreeway.com/deadCenter'
    },
    {
        id: 'l08',
        imageAltText: 'Los Angeles Asian Pacific Film Festival',
        imageSrc: laurelsLosAngelesAsianPacific,
        targetUrl: 'https://filmfreeway.com/laapff'
    },
    {
        id: 'l10',
        imageAltText: 'Sonoma International Film Festival',
        imageSrc: laurelsSonomaFilmFestival,
        targetUrl: 'https://filmfreeway.com/SonomaInternationalFilmFestival'
    },
    {
        id: 'l06',
        imageAltText: 'Bentonville Film Festival',
        imageSrc: laurelsBentonville,
        targetUrl: 'https://filmfreeway.com/BentonvilleFilmFestival'
    },
    {
        id: 'l05',
        imageAltText: 'Outfest Los Angeles LGBTQIA+ Film Festival',
        imageSrc: laurelsOutfestLosAngeles,
        targetUrl: 'https://filmfreeway.com/OutfestLosAngeles'
    },
    {
        id: 'l09',
        imageAltText: 'Palm Springs International ShortFest',
        imageSrc: laurelsPalmSpringsInternational,
        targetUrl: 'https://filmfreeway.com/PalmSpringsInternationalShortFest'
    },
    {
        id: 'l14',
        imageAltText: 'imagineNATIVE Film + Media Arts Festival',
        imageSrc: laurelsImagineNative,
        targetUrl: 'https://filmfreeway.com/imagineNATIVE'
    }
];

export default laurelsTwo;
