import React, { useContext, useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_APPLICANT, ADD_SAMPLE, UPDATE_APPLICATION_STEP } from '../../queries';
import ApplicantForm from './ApplicantForm';
import { ApplicationContext } from '../pages/Application';
import Button from '../modules/Button';

const PersonalInformation = props => {
    const { application = {}, refetch, setApplicantsValid } = useContext(ApplicationContext);
    const { applicants = [] } = application;
    const [addApplicant] = useMutation(ADD_APPLICANT);
    const [addSample] = useMutation(ADD_SAMPLE);
    const [updateApplicationStep] = useMutation(UPDATE_APPLICATION_STEP);
    const [validApplicants, setValidApplicants] = useState([]);
    const [loading, setLoading] = useState(false);

    const writers = applicants.filter(applicant => (applicant.type === 'writer' || applicant.type === 'both')).length;
    const directors = applicants.filter(applicant => (applicant.type === 'director' || applicant.type === 'both')).length;
    const hasDirectingSample = application.samples.find(sample => (sample.name === 'Directing Sample'));

    useEffect(() => {
        refetch();
    }, []);

    const handleAdd = async (type) => {
        setLoading(true);
        await addApplicant({ variables: { type } });
        await updateApplicationStep({ variables: { step: 1 } });
        if ((type === 'director' || type === 'both') && !hasDirectingSample) {
            await addSample({ variables: { name: 'Directing Sample' } });
        }
        refetch();
        setLoading(false);
    };
    const handleValid = (idx, isApplicantValid) => {
        const validApps = [...validApplicants];
        validApps[idx] = isApplicantValid;
        setValidApplicants(validApps);
        setApplicantsValid(!validApps.includes(false));
    };

    return (
        <div className='PersonalInformation'>
            {applicants.map((applicant, idx) => (
                <ApplicantForm
                    key={applicant.id}
                    applicant={applicant}
                    idx={idx}
                    handleValid={handleValid}
                />
            ))}
            <div className='flex center full-width'>
                {directors < 2 && <Button loading={loading} type='tertiary' disney icon='plus-circle' onClick={() => handleAdd('director')}>Add Director</Button>}
                {writers < 2 && <Button loading={loading} type='tertiary' disney icon='plus-circle' onClick={() => handleAdd('writer')}>Add Writer</Button>}
                {directors < 2 && writers < 2 && <Button loading={loading} type='tertiary' disney icon='plus-circle' onClick={() => handleAdd('both')}>Add Writer/Director</Button>}
            </div>
        </div>
    );
};

export default PersonalInformation;
