import React, { useContext, useEffect } from 'react';
import { ApplicationContext } from '../pages/Application';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import ReviewApplicant from './ReviewApplicant';
import ReviewUpload from './ReviewUpload';
import ReviewDirectingSample from './ReviewDirectingSample';
import ReviewLegal from './ReviewLegal';
import ReviewEmployment from './ReviewEmployment';

const ReviewApplication = props => {
    const { application, genres, sampleTypes, refetch } = useContext(ApplicationContext);
    const { applicants = [], uploads = [], samples = [] } = application || {};

    useEffect(() => {
        refetch();
    }, []);

    return (
        <div className='ReviewApplication'>
            <Row>
                <Col xs={10} lg={8} xsOffset={1} lgOffset={2}>
                    <Heading block number={2}>Review & Submit</Heading>
                    <Body block>Please review the information provided</Body>
                </Col>
            </Row>
            {applicants.map(applicant => (
                <ReviewApplicant applicant={applicant} key={applicant.id} />
            ))}
            <ReviewUpload uploads={uploads} applicants={applicants} genres={genres} />
            {samples.map(sample => (
                sample.params && <ReviewDirectingSample sample={sample} sampleTypes={sampleTypes} genres={genres} key={sample.id} />
            ))}
            <ReviewLegal applicants={applicants} />
            <ReviewEmployment applicants={applicants} />
        </div>
    );
};

export default ReviewApplication;
