import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../modules/Button';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const ReviewDirectingSample = props => {
    const { setStep } = useContext(ApplicationContext);
    const { url = 'N/A', password = '', params = [] } = props.sample || {};

    const findParamValue = (key) => {
        const param = params.find(param => param.key === key);
        return param ? param.value : 'N/A';
    };

    const title = findParamValue('title');
    const writer = findParamValue('writer');
    const director = findParamValue('director');
    const producer = findParamValue('producer');
    const logLine = findParamValue('logLine');
    const synopsis = findParamValue('synopsis');
    const year = findParamValue('year');
    const awards = findParamValue('awards');
    const runningTime = findParamValue('runningTime');
    const genreValue = findParamValue('genre');
    const genre = props.genres?.find(genre => genre.value === genreValue)?.text || 'N/A';
    const typeValue = findParamValue('sampleType');
    const sampleType = props.sampleTypes?.find(type => type.value === typeValue)?.text || 'N/A';

    return (
        <>
            <hr className='Application__divider' />
            <div>
                <Row>
                    <Col xs={6} xsOffset={1} lgOffset={2}>
                        <Heading block number={3} className='Application__Review__heading'>Directing Sample</Heading>
                    </Col>
                    <Col xs={5} md={2} lg={1} mdOffset={2} lgOffset={1}>
                        <Button color='info' type='tertiary' icon='edit-page' disney onClick={() => setStep(1)}>Edit</Button>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>URL</Heading>
                        <Body block>{url}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Password</Heading>
                        <Body block>{password ? '********' : 'None Needed'}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Title</Heading>
                        <Body block>{title}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Director</Heading>
                        <Body block>{director}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Producer</Heading>
                        <Body block>{producer}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Writer</Heading>
                        <Body block>{writer}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Synopsis</Heading>
                        <Body block>{synopsis}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Log Line</Heading>
                        <Body block>{logLine}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Year</Heading>
                        <Body block>{year}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Running Time</Heading>
                        <Body block>{runningTime}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Awards</Heading>
                        <Body block>{awards}</Body>
                    </Col>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={0}>
                        <Heading block number={4}>Genre</Heading>
                        <Body block>{genre}</Body>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                        <Heading block number={4}>Type of Sample</Heading>
                        <Body block>{sampleType}</Body>
                    </Col>
                </Row>
            </div>
        </>
    );
};

ReviewDirectingSample.propTypes = {
    sample: PropTypes.object,
    genres: PropTypes.array,
    sampleTypes: PropTypes.array
};

export default ReviewDirectingSample;
