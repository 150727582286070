import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../pages/Application';
import { ADD_APPLICANT_UPLOAD, REMOVE_UPLOAD } from '../../queries';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import UploadFile from './UploadFile';

const Upload = props => {
    const { showErrors } = useContext(ApplicationContext);
    const [addUpload] = useMutation(ADD_APPLICANT_UPLOAD);
    const [removeUpload] = useMutation(REMOVE_UPLOAD);
    const [errors, setErrors] = useState([]);
    const [filename, setFilename] = useState('');
    const [uploadFile, setUploadFile] = useState({});
    const [fileLoading, setLoading] = useState(false);

    useEffect(() => {
        let errs = [...errors];

        const file = props.applicant.uploads.find(file => (file.name === props.upload.name));

        if (file) {
            setFilename(file.filename);
            setUploadFile(file);
            errs = errs.filter(error => error !== 'file');
        } else if (!props.upload.isOptional) {
            errs = [...errs, 'file'];
        }
        setErrors(errs);
    }, [props.applicant]);

    useEffect(() => {
        console.log('id', props.id, !errors.length);
        props.handleValid(props.id, !errors.length);
    }, [errors]);

    const handleUpload = async ({ target: { validity, files: [file] } }) => {
        if (validity.valid) {
            setLoading(true);
            setFilename(file.name);
            const response = await addUpload({
                variables: {
                    applicantId: props.applicant.id,
                    name: props.upload.name,
                    file
                }
            });
            const { filename, id } = response.data.addApplicantUpload;
            setUploadFile({ name: filename, id });
            setErrors(errors => errors.filter(error => error !== 'file'));
            setFilename(filename);
            setLoading(false);
        }
    };

    const handleDelete = (id) => {
        if (id) {
            removeUpload({
                variables: {
                    id
                }
            });
            if (!props.upload.isOptional) {
                setErrors([...errors, 'file']);
            }
            setFilename('');
            setUploadFile({});
        }
    };

    return (
        <div className='Upload'>
            <hr className='Application__divider' />
            <Row>
                <Col xs={10} md={5} lg={4} xsOffset={1} lgOffset={2}>
                    <Heading block number={2}>{props.upload.title} ({props.applicant.firstName} {props.applicant.lastName})</Heading>
                    <Body dangerouslySetInnerHTML={{ __html: props.upload.description }} />
                </Col>
                <Col xs={10} md={4} lg={3} xsOffset={1}>
                    <UploadFile
                        handleUpload={handleUpload}
                        fileTypes={['.pdf']}
                        file={uploadFile || {}}
                        handleDelete={handleDelete}
                        loading={fileLoading}
                        filename={filename}
                        error={showErrors && errors.includes('file')}
                        errorMessage='Please upload a file.'
                    />
                </Col>
            </Row>

        </div>
    );
};

Upload.propTypes = {
    upload: PropTypes.object,
    id: PropTypes.string,
    handleValid: PropTypes.func,
    applicant: PropTypes.object
};
export default Upload;
