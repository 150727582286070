import { gql } from '@apollo/client';

export const ME = gql`
	query me {
		me {
			id
			firstName
			lastName
			email
		}
	}
`;

export const ADD_EMAIL = gql`
  mutation addNewsletterEmail($email: String!) {
    addNewsletterEmail(email: $email) {
      message
    } 
  }
`;
