import adrian from '../../images/adrian.png';
import xavier from '../../images/xavier.png';
import gabriela from '../../images/gabriela.png';
import niki from '../../images/niki.png';
import spencer from '../../images/spencer.png';
import waw from '../../images/W.A.W.png';
import kevin from '../../images/kevin.png';
import joel from '../../images/joel.png';
import jasmine from '../../images/jasmine.png';
import erica from '../../images/erica.png';
import alexb from '../../images/alexb.png';
import cashmere from '../../images/cashmere.png';

const directorsTwo = [
    {
        project: {
            projectName: 'Beautiful, FL',
            logline:
        'Determined to win an ice cream competition with a flavor inspired by her late Tia Abuela’s Puerto Rican treats a teen girl turns to her trailer park neighbors to help figure out the winning recipe.',
            finalists: [
                {
                    name: 'Gabriela Ortega, Director',
                    tag: 'gabriela',
                    description:
                    'Gabriela Ortega is an award-winning writer/director and actor from the Dominican Republic. She is a USC graduate, a 2023 Sundance screenwriting fellow, a 2022 Sundance interdisciplinary fellow, and a 2022-2023 Academy of Motion Pictures fellow. Her short film Huella, produced through the inaugural class of Indeed and Hillman Grad’s “Rising Voices” fellowship, was an official selection of the 2022 Sundance Film Festival and is being turned into a feature film after being selected for the 2022 Sundance Producers Lab and the 2023 Sundance Screenwriter’s Lab. Most recently, Gabriela was named one of Filmmaker Magazine’s 25 New Faces of Independent Film. She won the NBC & Telemundo Best Director Award at the 2022 LALIFF and Best New Filmmaker at the annual NFMLA awards. Her latest film, Beautiful, FL, produced through the Disney Launchpad program, will premiere on Disney+ in 2023, and her first film, Papi, is available to stream on HBO and HBO Max. She is developing a VR experience with Indeed and Hillman Grad, exploring recidivism and re-entry after incarceration in the United States, and writing for the new BIOSHOCK 4 game. Gabriela is also penning an original one-woman show titled Passenger, to be produced in Los Angeles later this year, which will be adapted into a TV series. Her work lives within the intersection of fiction and poetry and aims to draw cultural bridges that lead to the Caribbean through intersectionality, duality, and ancestral memory.',
                    image: gabriela
                },
                {
                    name: 'Joel Perez, Writer',
                    tag: 'joel',
                    description:
                    'Joel Perez is an award-winning writer and actor living in NYC. He wrote and performed a solo musical comedy show Playing With Myself at Joe’s Pub and Ars Nova. Other writing includes Stir, a play commission from the Old Globe Theater which was developed at The Huntington Theatre and received an Elliot Norton Award for Best New Script and the musical Lost City Radio (NYSAF, Berkeley Rep Ground Floor, and Rhinebeck Writers Retreat). He is a 2021 Warner Bros. Discovery 150 Artist Grant recipient for his original comedy pilot You’re Tired, You’re Poor. He is the winner of the 2021 Voces Latinx National Playwriting Competition for his play From the Fountain. He is a 2019 NYSCA/NYFA Artist Fellow in Playwriting from NYFA. His acting work includes Fun Home (Broadway), Kiss My Aztec! (Hartford Stage), As You Like It (The Public Theater), and Sweet Charity (New Group; Lortel Award for Outstanding Supporting Actor in a Musical). Film work includes tick, tick… Boom! (Netflix). TV work includes Jesus Christ Superstar Live! (NBC), Person of Interest (CBS) and Odd Mom Out (Bravo). He performed with the UCB Maude sketch team Peach. Follow him at @misterjoelperez and visit www.misterjoelperez.com.',
                    image: joel
                },
                {
                    name: 'Adrian Ferbeyre, Writer',
                    tag: 'adrian',
                    description:
                    'Adrian Ferbeyre is a Cuban American writer and illustrator whose work focuses on the intersection of his Queer and Latine identities. He contributed to "Geeks OUT Presents: Power," a queer comic anthology, and was highlighted as an out.com LGBTQ+ artist to watch. He lives in New York City with his husband and a grumpy black cat and dabbles in sorcery on the weekends.',
                    image: adrian
                }
            ]
        }
    },
    {
        project: {
            projectName: 'Black Belts',
            logline:
        'Determined to prove himself a Compton legend like his ex-fighter dad, an offbeat teen steals the family’s secret martial arts technique to use at a well-renowned underground dojo. Seeking respect amongst peers, he learns quickly that there’s more to being a man than throwing hands.',
            finalists: [
                {
                    name: 'Spencer Glover, Director',
                    tag: 'spencer',
                    description:
            'Writer, director, ramen enthusiast; in no particular order. Spencer Glover is a Filipino and African American filmmaker based in Los Angeles, CA. Pulling from his life experiences growing up in Virginia and Tennessee, his central themes focus on creating awe, wonder, and relatability around the beauty of life. Spencer is a Half Initiative Directing Mentee (Ryan Murphy TV) and his films have screened and won numerous awards from Oscar qualifying festivals. His film “Message Read" premiered globally with Vivica A. Fox and the FOX SOUL Screening Room and won “Best Film” from the Capital City Black Film Festival. His goals include fostering an environment of collaboration, education, and inspiration alongside his partner through their production company, RM108.',
                    image: spencer
                },
                {
                    name: 'Xavier Stiles, Writer',
                    tag: 'xavier',
                    description:
            'Xavier Stiles is a writer-producer-performer born in LA and raised in the STL. He credits his minister mother for giving him the courage to jump from football to Edinburgh musicals, and his LAPD father from Compton for his attraction to writing gritty dramas with heart.  Xavier is a type 1 diabetes advocate, retired Hollywood bouncer, and has produced shows for artists such as Lil Nas X and Saweetie. He’s been selected as a finalist for the Disruptors Fellowship. He’s credited as support staff on Chambers and Percy Jackson and Olympians. He’s a big fan of Marvel and Pixar.',
                    image: xavier
                }
            ]
        }
    },
    {
        project: {
            projectName: 'Maxine',
            logline:
        'Nervous about introducing her first girlfriend to her family, Allie gets help from the spirit of a long-lost relative during the Hungry Ghost Festival.',
            finalists: [
                {
                    name: 'Niki Ang, Writer/Director',
                    tag: 'niki',
                    description:
            'Niki Ang is a queer, Singaporean-Chinese American writer and director born and raised in Singapore before moving to the United States at a young age. Niki’s latest film MAXINE (Disney+) was made with Disney’s Launchpad Program and will premiere on Disney+ in 2023. The film stars Margaret Cho and was produced by Andrew Ahn (FIRE ISLAND, DRIVEWAYS) and Disney’s Live Action Production team. Her previous short film WERE YOU GAY IN HIGH SCHOOL? is a hybrid live-action/animation film featuring animated sequences from Sara Gunnarsdottir (MY YEAR OF DICKS, DIARY OF A TEENAGE GIRL) and Amanda Bonaiuto. The film premiered at the 2020 Bentonville Film Festival and played at festivals worldwide. The film is distributed by Frameline Voices, was optioned by OrionTV, and developed into a comedy series. Niki’s work across genres intersects Asian American and queer perspectives with humor and heart. She loves grounded stories with relatable characters that show “coming-of-age” can happen anywhere in life. She currently lives in Los Angeles with her wife and is the quintessential middle child.',
                    image: niki
                }
            ]
        }
    },
    {
        project: {
            projectName: 'Project CC',
            logline:
        'A brilliant child scientist must join forces with her sister when a cloning experiment goes awry.',
            finalists: [
                {
                    name: 'Cashmere Jasmine, Director',
                    tag: 'cashmere',
                    description:
            'Cashmere Jasmine is an Afro-Caribbean Director with southern roots based in LA. Cashmere creates genre-bending media inspired by anime, witchcraft, and a dash of Plato to reimagine depictions of intersectional disability-inclusive stories using dark humor and surrealist imagery. In 2021, Cashmere was a part of Amy Aniobi’s TRIBE, the Black Magic Collective’s All Access Fellowship, and RespectAbility’s Summer Entertainment Lab. She also received the Sundance Uprise grant for her short film, Oreo: A Trilogy which became a 2022 Slamdance festival selection. Her surrealist feature Bitter, about an irresponsible dialysis patient, earned her a spot in the Sundance Accessible Futures Intensive to workshop her feature. Currently, Cashmere continues pre-production for her upcoming horror short film.',
                    image: cashmere
                },
                {
                    name: 'Jasmine Johnson, Writer',
                    tag: 'jasmine',
                    description:
            'Jasmine has been a writer ever since she was a small child, but her journey has been less than ordinary. Originally from New Jersey, she has a B.F.A. in film and television from New York University and a J.D. from Georgetown University Law Center. She interned in the writing/research department at Saturday Night Live and in the national legal department at the American Civil Liberties Union. She has taken improv and sketch writing classes at Upright Citizens Brigade and The Groundlings and she has represented juveniles in court. Jasmine (and her student loans) currently live in Los Angeles where she works in law firms by day and devotes her nights and weekends to writing scripts that center women and girls who are fat, black, and proud. In 2018 her pilot Lap Band, a comedy about three fat teen girls who form a music group, was chosen as one of the alumni scripts for Other, a Showcase of Under-Heard Voices presented by New York University Tisch School of the Arts Alumni Relations. In 2019 Jasmine’s short script Adeline, The Great was selected for the AT&T HelloLab Mentorship Program. In 2021 Jasmine was a finalist in the Universal Animation Writers Program.',
                    image: jasmine
                }
            ]
        }
    },
    {
        project: {
            projectName: 'The Ghost',
            logline:
        '12-year-old Clarice Cheung feels like she’s invisible — especially next to her older sister Naomi. But when a fight breaks out at the dinner table, it awakens a ghost that begins taking the family, one by one. Now Clarice and Naomi must work together to stop this powerful spirit, before their family is torn apart forever.',
            finalists: [
                {
                    name: 'Erica Eng, Director',
                    tag: 'erica',
                    description:
            'Erica Eng is a fifth-generation Chinese American director based out of Los Angeles. She wrote and directed "Americanized" based on her experiences growing up in Oakland and playing high school basketball. The film earned a Vimeo Staff Pick and garnered 26 awards at festivals including Palm Springs International ShortFest, Bentonville Film Festival, and the Oscar-qualifying award at Cinequest. Meanwhile, Erica\'s latest film “Off Fairfax” is a comedic mystery-thriller that premiered at Tribeca Film Festival and took home an Honorable Mention at Slamdance for “Best Episodic.” Aside from narrative directing, Erica’s commercial work has been featured in Shots, Muse by Clio, and recognized as AdAge’s “Editor’s Pick” and “Pick of the Week.” She was selected for SHOOT Magazine’s New Directors Showcase and the Commercial Directors Diversity Program – a fellowship partnered with the Directors Guild of America and the Association of Independent Commercial Producers. Currently, Erica is in development on her narrative feature debut. Her combined background in cinematography and dance influences the rhythm and energy behind her visual style. Inspired by her Oakland roots, Erica is an emotional storyteller who creates work that reflects the diverse community in which she grew up.',
                    image: erica
                },
                {
                    name: 'Kevin Jihyek Park, Writer',
                    tag: 'kevin',
                    description:
            "Born in South Korea and raised in Northern Virginia, Kevin loves to create genre-mixing stories about complex friendships and otherworldly settings. His experimental short MA’S CANH CHUA RECIPE won the top prize at Palette Poetry and screened at the 2023 Poetry Film Festival. His short screenplay A SONATA FOR J was a finalist in the 2021 Richmond International Film Festival and his original children’s script WE MAKE LEMONADE was a semi-finalist in the 2020 Screencraft Animation Contest. A graduate of NYU's Tisch School of the Arts, Kevin also has 6+ years of experience working in educational media, creating classroom resources for PBS and Sesame Workshop. In his free time, he likes to play video games, catch up on his reading list, and learn animal trivia.",
                    image: kevin
                }
            ]
        }
    },
    {
        project: {
            projectName: 'The Roof',
            logline:
        'After being sent to stay with their grandfather, a Northern Cheyenne teen discovers a connection to their family and community in a way they never thought possible.',
            finalists: [
                {
                    name: 'Alexander Bocchieri, Director',
                    tag: 'alex',
                    description:
            'Alex is a Native Hawaiian filmmaker who lives and works in Honolulu and Los Angeles. After graduating from the University of Hawai‘i, Alex turned his passion for storytelling toward creating documentaries, leading to his work on the celebrated film, Ike: Knowledge is Everywhere. From there he told stories of Hawai‘i heroes when he directed the independent feature film, GO FOR BROKE, a groundbreaking Hawai‘i-produced war drama that went on to screen at festivals around the world. Since then, he has written and directed award-winning short films and commercials, while working with nonprofits to document individuals and institutions that are trying to make this world a better place.',
                    image: alexb
                },
                {
                    name: 'W.A.W. Parker, Writer',
                    tag: 'waw',
                    description:
            'W.A.W. Parker is a queer/Two-Spirit/Northern Cheyenne/white/writer in Los Angeles. They grew up in Montana and studied film and gender at Harvard. Their pilot THE BARON made the GLAAD List and the Indigenous List. Their novel “The Wasteland” about T.S. Eliot won the American Fiction Award and the Foreword INDIES Book of the Year Award in LGBTQ+ Fiction.',
                    image: waw
                }
            ]
        }
    }
];

export default directorsTwo;
