import { gql } from '@apollo/client';

export const GET_APPLICATION = gql`
  query application {
    application {
      id
      coreId
      type
      step
      status
      acceptTerms
      eligible
      applicants {
        id
        firstName
        lastName
        type
        email
        dob
        state
        zipcode
        phoneNumber
        eeo {
          optOut
          gender
          race
          ethnicity
          veteran
          disability
          employee
        }
        params {
          key
          value
        }
        uploads {
          id
          name
          filename
          params {
            key
            value
          }
        }
        
      }
      uploads {
        id
        name
        filename
        params {
          key
          value
        }
      }
      samples {
        
        id
        name
        url
        password
        params {
          key
          value
        }
        uploads {
          id
          name
          filename
          params {
            key
            value
          }
        }
      }
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication ($type: String){
      createApplication(type: $type) {
        id
        step
      }
  }
`;

export const UPDATE_APPLICATION_STEP = gql`
  mutation updateApplication($step: Int) {
    updateApplication(step: $step) {
      id
      step
    }
  }
`;

export const UPDATE_APPLICATION_STATUS = gql`
  mutation updateApplication($status: ApplicationStatus) {
    updateApplication(status: $status) {
      id
      status
    }
  }
`;
export const UPDATE_APPLICATION_TERMS = gql`
  mutation updateApplication($acceptTerms: Boolean, $eligible: Boolean) {
    updateApplication(acceptTerms: $acceptTerms, eligible: $eligible) {
      id
      acceptTerms
      eligible
    }
  }
`;

export const ADD_APPLICANT = gql`
  mutation addApplicant($type: String) {
    addApplicant(applicant: {type: $type}) {
      id
    }
  }
`;

export const UPDATE_APPLICANT = gql`
  mutation updateApplicant($id: String!, $applicant: ApplicantInput!) {
    updateApplicant(id: $id, applicant: $applicant) {
      id
      firstName
      lastName
      type
      email
      dob
      phoneNumber
      state
      zipcode
      eeo {
        optOut
        race
        gender
        ethnicity
        veteran
        disability
        employee
      }
      params {
        key
        value
      }
    }
  }
`;

export const REMOVE_APPLICANT = gql`
  mutation removeApplicant($id: String!) {
    removeApplicant(id: $id) {
      message
    }
  }
`;

export const ADD_UPLOAD = gql`
  mutation addUpload($name: String!, $file: Upload, $params: [ParamInput]) {
    addUpload(fileUpload: {
      name: $name
      file: $file
      params: $params
    }) {
      id
      filename
      params {
        key
        value
      }
    }
  }
`;

export const ADD_APPLICANT_UPLOAD = gql`
  mutation addApplicantUpload($applicantId: String!, $name: String!, $file: Upload) {
    addApplicantUpload(
    applicantId: $applicantId,
    fileUpload: {
      name: $name
      file: $file
    }) {
      id
      filename
      params {
        key
        value
      }
    }
  }
`;

export const ADD_SAMPLE_UPLOAD = gql`
  mutation addSampleUpload($sampleId: String!, $name: String!, $file: Upload) {
    addSampleUpload(
    sampleId: $sampleId,
    fileUpload: {
      name: $name
      file: $file
    }) {
      id
      filename
      params {
        key
        value
      }
    }
  }
`;

export const UPDATE_UPLOAD = gql`
  mutation updateUpload($id: String!, $name: String, $file: Upload, $params: [ParamInput]) {
    updateUpload(id: $id, fileUpload: {
      name: $name,
      file: $file,
      params: $params
    }) {
      id
      filename
      params {
        key
        value
      }
    }
  }
`;

export const REMOVE_UPLOAD = gql`
  mutation removeUpload($id: String!) {
    removeUpload(id: $id) {
      message
    }
  }
`;

export const ADD_SAMPLE = gql`
  mutation addSample($name: String) {
    addSample(sample: {
      name: $name
    }) {
      id
    }
  }
`;

export const UPDATE_SAMPLE = gql`
  mutation updateSample($id: String!, $url: String, $password: String, $params: [ParamInput]) {
    updateSample(id: $id, sample: {
      url: $url
      password: $password
      params: $params
    }) {
      id
    }
  }
`;

export const REMOVE_SAMPLE = gql`
  mutation removeSample($id: String!) {
    removeSample(id: $id) {
      message
    }
  }
`;

export const DELETE_APPLICATION = gql`
  mutation deleteApplication {
    deleteApplication {
      message
    }
  }
`;
