import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Body } from '../modules/Typography';
import Button from '../modules/Button';

const EmailSent = props => {
    return (
        <div className='ResetPassword'>
            <Modal show onHide={() => {}} className='ResetPassword__Modal'>
                <Modal.Header className='flex column align-center'>
                    <Modal.Title>Check your Email Inbox</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex column align-flex-start '>
                        <Body className='ResetPassword__Modal__subtitle'>An email was sent to {props.email}. Please check your email to reset your password.</Body>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container sign-in'>
                        <Button block type='primary' link='/'>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

EmailSent.propTypes = {
    email: PropTypes.string
};

export default EmailSent;
