import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const ReviewUpload = props => {
    const { setStep } = useContext(ApplicationContext);
    const script = props.uploads?.find(upload => upload.name === 'script') || null;
    let scriptFile = 'N/A';
    let scriptWrittenBy = 'Me';
    let scriptGenre = 'N/A';

    if (script) {
        scriptFile = script.filename || 'N/A';
        const scriptWrittenByValue = script.params?.find(param => param.key === 'writtenBy')?.value || 'me';
        if (scriptWrittenByValue === 'other') {
            scriptWrittenBy = 'Another writer and me.';
        } else if (scriptWrittenByValue === 'team') {
            scriptWrittenBy = 'Another Writing Team';
        }
        const scriptGenreValue = script.params?.find(param => param.key === 'genre')?.value || '';
        scriptGenre = props.genres?.find(genre => genre.value === scriptGenreValue)?.text || 'N/A';
    }

    const apps = props.applicants?.map(applicant => ({
        name: `${applicant.firstName || 'N/A'} ${applicant.lastName || 'N/A'}`,
        resume: applicant.uploads?.find(upload => upload.name === 'resume') || null,
        reference: applicant.uploads?.find(upload => upload.name === 'reference') || null
    })) || [];

    return (
        <>
            <hr className='Application__divider' />
            <div>
                <Row>
                    <Col xs={6} xsOffset={1} lgOffset={2} className='flex column'>
                        <Heading block number={3} className='Application__Review__heading'>Uploads</Heading>
                    </Col>
                    <Col xs={5} md={2} lg={1} mdOffset={2} lgOffset={1}>
                        <Button color='info' type='tertiary' icon='edit-page' disney onClick={() => setStep(1)}>Edit</Button>
                    </Col>
                </Row>
                {script && (
                    <>
                        <Row className='Application__Review__row'>
                            <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                                <Heading block number={4}>Script</Heading>
                                <div className='flex flex-start'>
                                    <Icon name='file-pdf' className=' Upload__icon info-text' />
                                    <Body block color='info' className='Upload__filename'>{scriptFile}</Body>
                                </div>
                            </Col>
                        </Row>
                        <Row className='Application__Review__row'>
                            <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                                <Heading block number={4}>Written By:</Heading>
                                <Body block>{scriptWrittenBy}</Body>
                            </Col>
                            <Col xs={11} lg={5} xsOffset={1} lgOffset={2}>
                                <Heading block number={4}>Genre:</Heading>
                                <Body block>{scriptGenre}</Body>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
            <Row className='Application__Review__row'>
                <Col xs={11} lg={10} lgOffset={2} xsOffset={1}>
                    <Heading block number={4}>{`Resume${apps.length !== 1 ? 's' : ''} and Reference${apps.length !== 1 ? 's' : ''}`}</Heading>
                    {apps.map((applicant, index) => (
                        <React.Fragment key={index}>
                            <Body block className='Application__Review__resumeName spacing-sm'>{applicant.name}</Body>
                            <div className='flex flex-start'>
                                <Icon name='file-pdf' className=' Upload__icon info-text' />
                                <Body block color='info' className='Upload__filename'>{applicant.resume ? applicant.resume.filename : 'N/A'}</Body>
                            </div>
                            {applicant.reference && (
                                <div className='flex flex-start'>
                                    <Icon name='file-pdf' className=' Upload__icon info-text' />
                                    <Body block color='info' className='Upload__filename'>{applicant.reference.filename}</Body>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </Col>
            </Row>
        </>
    );
};

ReviewUpload.propTypes = {
    uploads: PropTypes.array,
    applicants: PropTypes.array,
    genres: PropTypes.array
};

export default ReviewUpload;
