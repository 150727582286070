import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import fetch from '../utils/fetch';
import { ME } from '../queries';

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
    const cookies = new Cookies();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [role, setRole] = useState('director');
    const [getMe, { data }] = useLazyQuery(ME);
    const [applicationsOpen, setApplicationsOpen] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const accessToken = cookies.get('accessToken');
        const refreshToken = cookies.get('refreshToken');
        setToken({ accessToken, refreshToken });
        const checkApplications = async () => {
            const { applicationsOpen } = await fetch('/api/config');
            setApplicationsOpen(applicationsOpen);
        };
        checkApplications();
    }, []);

    useEffect(() => {
        if (token && token.accessToken && !user) {
            getMe();
        }
    }, [token]);

    useEffect(() => {
        if (data) {
            setUser(data.me);
        }
    }, [data]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                role,
                setRole,
                applicationsOpen,
                setMobileMenuOpen,
                mobileMenuOpen
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.object
};

export default UserContextProvider;
