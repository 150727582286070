import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_SAMPLE, REMOVE_SAMPLE } from '../../queries';
import TextField from '../modules/TextField';
import Select from '../modules/Select';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const DirectingSample = (props) => {
    const { sampleTypes, genres, showErrors, refetch } = useContext(ApplicationContext);
    const { sample } = props;
    const [updateSample] = useMutation(UPDATE_SAMPLE);
    const [removeSample] = useMutation(REMOVE_SAMPLE);
    const [showPassword, setShow] = useState(false);
    const [title, setTitle] = useState('');
    const [writer, setWriter] = useState('');
    const [director, setDirector] = useState('');
    const [producer, setProducer] = useState('');
    const [logLine, setLogLine] = useState('');
    const [synopsis, setSynopsis] = useState('');
    const [year, setYear] = useState('');
    const [awards, setAwards] = useState('');
    const [runningTime, setRunningTime] = useState('');
    const [url, setUrl] = useState('');
    const [password, setPassword] = useState('');
    const [sampleType, setType] = useState('');
    const [genre, setGenre] = useState('');

    const [errors, setErrors] = useState([]);

    const updateErrors = () => {
        let errors = [];
        const params = sample.params || [];
        ['url'].forEach(key => {
            if (sample[key]) {
                errors = errors.filter(error => error !== key);
            } else {
                errors = [...errors, key];
            }
        });
        [
            'writer',
            'director',
            'title',
            'producer',
            'logLine',
            'synopsis',
            'year',
            'awards',
            'runningTime',
            'sampleType',
            'genre'
        ].forEach(key => {
            if (params.find(param => param.key === key) && params.find(param => param.key === key).value) {
                errors = errors.filter(error => error !== key);
            } else {
                errors = [...errors, key];
            }
        });
        setErrors(errors);
    };

    useEffect(() => {
        const { params } = sample;
        setUrl(sample.url || '');
        setPassword(sample.password || '');
        if (params) {
            const title = params.find(param => param.key === 'title');
            const writer = params.find(param => param.key === 'writer');
            const director = params.find(param => param.key === 'director');
            const producer = params.find(param => param.key === 'producer');
            const logLine = params.find(param => param.key === 'logLine');
            const synopsis = params.find(param => param.key === 'synopsis');
            const year = params.find(param => param.key === 'year');
            const awards = params.find(param => param.key === 'awards');
            const runningTime = params.find(param => param.key === 'runningTime');
            const genre = params.find(param => param.key === 'genre');
            const sampleType = params.find(param => param.key === 'sampleType');
            setTitle(title ? title.value : '');
            setWriter(writer ? writer.value : '');
            setDirector(director ? director.value : '');
            setProducer(producer ? producer.value : '');
            setLogLine(logLine ? logLine.value : '');
            setSynopsis(synopsis ? synopsis.value : '');
            setYear(year ? year.value : '');
            setAwards(awards ? awards.value : '');
            setRunningTime(runningTime ? runningTime.value : '');
            setGenre(genre ? genre.value : '');
            setType(sampleType ? sampleType.value : '');
        }
        updateErrors();
    }, [sample]);

    useEffect(() => {
        let valid = true;
        if (
            !url ||
            !title ||
            !writer ||
            !director ||
            !producer ||
            !logLine ||
            !synopsis ||
            !year ||
            !awards ||
            !runningTime ||
            !genre ||
            !sampleType ||
            errors.length
        ) {
            valid = false;
        }
        props.handleValid(valid);
    }, [errors, url, title, writer, director, producer, logLine, synopsis, year, awards, runningTime, genre, sampleType]);

    const handleBlur = (id, isValid) => {
        if (!isValid && id !== 'password') {
            setErrors(errors => [...errors, id]);
        } else {
            setErrors(errors => errors.filter(error => error !== id));
        }
        handleSave();
    };

    const handleTextBlur = (e) => {
        const { value } = e.target;
        const id = e.target.id.split('-')[0];
        handleBlur(id, value);
    };

    const handleSelect = ({ id, value }) => {
        if (id === 'sampleType') {
            setType(value);
        } else if (id === 'genre') {
            setGenre(value);
        }
        if (!value) {
            setErrors(errors => [...errors, id]);
        } else {
            setErrors(errors => errors.filter(error => error !== id));
        }
        handleSave(id, value);
    };

    const handleSave = async (id, value) => {
        const newType = id && id === 'sampleType' ? value : sampleType;
        const newGenre = id && id === 'genre' ? value : genre;
        await updateSample({
            variables: {
                id: sample.id,
                url,
                password,
                params: [
                    { key: 'title', value: title },
                    { key: 'writer', value: writer },
                    { key: 'director', value: director },
                    { key: 'producer', value: producer },
                    { key: 'logLine', value: logLine },
                    { key: 'synopsis', value: synopsis },
                    { key: 'year', value: year },
                    { key: 'awards', value: awards },
                    { key: 'runningTime', value: runningTime },
                    { key: 'genre', value: newGenre },
                    { key: 'sampleType', value: newType }
                ]

            }
        });
        // refetch();
    };

    const handleRemove = async () => {
        await removeSample({
            variables: { id: sample.id }
        });
        refetch();
    };

    return (
        <div className='Sample'>
            <Row>
                <Col xs={6} xsOffset={1} lgOffset={2} className='flex column'>

                    <Heading block number={2}>{sample.name}</Heading>
                    <Body block>One (1) directing sample (up to thirty (30) minutes in length total and not less than five (5) minutes) that showcase your unique tone and cinematic style. This sample can be from any genre and does not need to align tonally with the Disney brand. The sample must be a scripted, live action, narrative work with dialogue, and must be at least five (5) minutes long.
                    </Body>
                    <Body block>
                        Please provide the samples via YouTube or Vimeo links with passwords that are active
                        until at least December 31, 2021.

                    </Body>
                    <Body block>All Fields are Required</Body>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={5} lg={4} xsOffset={1} lgOffset={2}>

                    <TextField
                        value={title}
                        id={`title-${props.idx}`}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('title')}
                        errorMessage='Please include the title.'
                        label='Title'
                    />
                    <TextField
                        value={writer}
                        id={`writer-${props.idx}`}
                        onChange={e => setWriter(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('writer')}
                        errorMessage='Please include the writer.'
                        label='Writer(s)'
                    />
                    <TextField
                        value={director}
                        id={`director-${props.idx}`}
                        onChange={e => setDirector(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('director')}
                        errorMessage='Please include the director.'
                        label='Director(s)'
                    />
                    <TextField
                        value={producer}
                        id={`producer-${props.idx}`}
                        onChange={e => setProducer(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('producer')}
                        errorMessage='Please include the producer.'
                        label='Producer(s)'
                    />
                    <TextField
                        value={logLine}
                        id={`logLine-${props.idx}`}
                        onChange={e => setLogLine(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('logLine')}
                        errorMessage='Please include the log line.'
                        label='Log Line'
                    />
                    <TextField
                        value={synopsis}
                        id={`synopsis-${props.idx}`}
                        onChange={e => setSynopsis(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('synopsis')}
                        errorMessage='Please include the synopsis.'
                        label='Synopsis'
                    />
                </Col>
                <Col xs={10} md={6} lg={4} xsOffset={1} lgOffset={0}>
                    <TextField
                        value={url}
                        id={`url-${props.idx}`}
                        onChange={e => setUrl(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('url')}
                        errorMessage='Please include the url.'
                        label='YouTube/Vimeo link'
                    />
                    <div className='flex align-flex-end'>
                        <TextField
                            value={password}
                            id={`password-${props.idx}`}
                            onChange={e => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            onBlur={handleTextBlur}
                            label='If this link requires a password, type it here:'
                        />
                        <Icon className='button Sample__eye Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                    </div>
                    <div className='selector__parent'>
                        <Select
                            label='Is your sample a short, feature film, or webisode?'
                            options={sampleTypes}
                            id='sampleType'
                            selectedValue={sampleType}
                            onChange={handleSelect}
                            placeholder='Please select...'
                            error={showErrors && errors.includes('sampleType')}
                            errorMessage='Please make a selection.'
                        />
                    </div>
                    <TextField hidden className='visually-hidden' />
                    <div className='selector__parent'>
                        <Select
                            label='Genre:'
                            options={genres}
                            id='genre'
                            selectedValue={genre}
                            onChange={handleSelect}
                            placeholder='Please select...'
                            error={showErrors && errors.includes('genre')}
                            errorMessage='Please select a genre.'
                        />
                    </div>
                    <TextField
                        value={year}
                        id={`year-${props.idx}`}
                        onChange={e => setYear(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('year')}
                        errorMessage='Please include the year.'
                        label='Year Created'
                    />
                    <TextField
                        value={awards}
                        id={`awards-${props.idx}`}
                        onChange={e => setAwards(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('awards')}
                        errorMessage='Please include any awards.'
                        label='Awards'
                    />
                    <TextField
                        value={runningTime}
                        id={`runningTime-${props.idx}`}
                        onChange={e => setRunningTime(e.target.value)}
                        onBlur={handleTextBlur}
                        error={showErrors && errors.includes('runningTime')}
                        errorMessage='Please include the running time.'
                        label='Running Time'
                    />
                </Col>
            </Row>
            {sample.name.split('-')[1] === '2' && (
                <div className='flex center spacing-sm'>
                    <Button type='tertiary' icon='close' onClick={handleRemove}>Remove Sample</Button>
                </div>
            )}
        </div>
    );
};

DirectingSample.propTypes = {
    idx: PropTypes.number,
    handleValid: PropTypes.func,
    sample: PropTypes.object
};

export default DirectingSample;
