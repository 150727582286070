import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import { ApplicationContext } from '../pages/Application';

const ReviewLegal = props => {
    const { setStep } = useContext(ApplicationContext);

    const apps = props.applicants?.map((applicant, index) => ({
        id: applicant.id || index,
        name: `${applicant.firstName || 'N/A'} ${applicant.lastName || 'N/A'}`,
        agreement: applicant.uploads?.find(upload => upload.name === 'agreement') || null
    })) || [];

    return (
        <>
            <hr className='Application__divider' />
            <div>
                <Row>
                    <Col xs={6} xsOffset={1} lgOffset={2} className='flex column'>
                        <Heading block number={3} className='Application__Review__heading'>Legal</Heading>
                    </Col>
                    <Col xs={5} md={2} lg={1} mdOffset={2} lgOffset={1}>
                        <Button color='info' type='tertiary' icon='edit-page' disney onClick={() => setStep(2)}>Edit</Button>
                    </Col>
                </Row>
                <Row className='Application__Review__row'>
                    <Col xs={11} lg={10} lgOffset={2} xsOffset={1}>
                        <Heading block number={4}>{`Submission Agreement${apps.length !== 1 ? 's' : ''}`}</Heading>
                        {apps.map(applicant => (
                            <Fragment key={`agreement-${applicant.id}`}>
                                <Body block className='Application__Review__resumeName spacing-sm'>{applicant.name}</Body>
                                <div className='flex flex-start'>
                                    <Icon name='file-pdf' className=' Upload__icon info-text' />
                                    <Body block color='info' className='Upload__filename'>{applicant.agreement ? applicant.agreement.filename : 'N/A'}</Body>
                                </div>
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </div>
        </>
    );
};

ReviewLegal.propTypes = {
    applicants: PropTypes.array
};

export default ReviewLegal;
