import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Body, Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import fetch from '../../utils/fetch';
import { UserContext } from '../../contexts/UserContextProvider';

const CreateAccountModal = props => {
    const { setUser, setToken } = useContext(UserContext);
    const [showPassword, setShow] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const testEmail = (e) => {
        const { value } = e.target;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setErrors(errors.filter(error => error !== 'email'));
        } else {
            setErrors([...errors, 'email']);
        }
    };

    const handleFirstName = (e) => {
        const { value } = e.target;
        setFirstName(value);
    };
    const handleLastName = (e) => {
        const { value } = e.target;
        setLastName(value);
    };
    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };
    const handlePassword = (e) => {
        const { value } = e.target;
        setPassword(value);
        setErrors(errors.filter(error => error !== 'password'));
        setErrorMessage('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const { account, token, message } = await fetch('/auth/sentry/signup', {
            method: 'POST',
            body: {
                email: email.toLowerCase(),
                password,
                givenName: firstName,
                familyName: lastName
            }
        });
        if (message) {
            if (message === 'existing account') {
                setErrorMessage('A user with that email already exists.');
                setErrors(['email']);
            } else if (message.includes('Member must have length')) {
                setErrorMessage('Your password must be at least 6 characters.');
                setErrors(['password']);
            } else if (message.includes('uppercase characters')) {
                setErrorMessage('Your password must have uppercase characters.');
                setErrors(['password']);
            } else if (message.includes('numeric characters')) {
                setErrorMessage('Your password must have numeric characters.');
                setErrors(['password']);
            } else {
                setErrorMessage('There was an error creating this user.');
                setErrors(['password']);
            }
        } else {
            setUser(account);
            setToken({ accessToken: token.access_token, refreshToken: token.refresh_token });
        }
    };

    return (
        <Modal show={props.open} onHide={props.handleClose} className='Login__Modal'>
            <Modal.Header closeButton>
                <Modal.Title>Create Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Caption className='Login__Modal__required' block number={1}>*Required Field</Caption>
                <TextField
                    placeholder='First Name*'
                    value={firstName}
                    errorMessage='Please include a first name.'
                    error={errors.includes('firstName')}
                    onChange={handleFirstName}
                />
                <TextField
                    placeholder='Last Name*'
                    value={lastName}
                    errorMessage='Please include a last name.'
                    error={errors.includes('lastName')}
                    onChange={handleLastName}
                />
                <TextField
                    placeholder='Email Address*'
                    value={email}
                    errorMessage={errorMessage || 'Please include a valid email.'}
                    error={errors.includes('email')}
                    onChange={handleEmail}
                    onBlur={testEmail}
                />
                <div className='flex align-center Login__Modal__password'>
                    <TextField
                        className='right-10 '
                        padded={false}
                        placeholder='Password*'
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        errorMessage={errorMessage || 'Please include a password.'}
                        error={errors.includes('password')}
                        onChange={handlePassword}
                        onKeyPress={handleKeyPress}
                    />
                    <Icon className='button Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                </div>
                <Caption block>Password Requirements</Caption>
                <Caption block number={2}>- At least 6 characters</Caption>
                <Caption block number={2}>- At least 1 uppercase letter</Caption>
                <Caption block number={2}>- At least 1 lowercase letter</Caption>
                <Caption block number={2}>- At least 1 number</Caption>
                <Body block className='spacing-sm'>By creating an account, I agree to the{' '}
                    <a href='https://disneytermsofuse.com/' target='_blank' rel='noopener noreferrer'>Terms of Use</a>
                    {' '}and acknowledge that I have read the{' '}
                    <a href='https://privacy.thewaltdisneycompany.com/en/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
                    {' '}and{' '}
                    <a href='https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-california-privacy-rights/#heading1' target='_blank' rel='noopener noreferrer'>California Privacy Notice</a>
                    .
                </Body>
            </Modal.Body>
            <Modal.Footer>
                <div className='btn-container'>
                    <Button block type='primary' onClick={handleSubmit} disabled={errors.length || !email || !firstName || !lastName || !password}>Create Account</Button>
                    <Button block type='secondary-outline' onClick={props.handleLogin}>Sign In</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

CreateAccountModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleLogin: PropTypes.func

};

export default CreateAccountModal;
