import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopNav from './TopNav';
import Footer from './Footer';
import Application from '../pages/Application';

const Layout = props => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (window.s) {
            window.s.pageName = pathname;
            window.s.t();
        }
    }, [pathname, window.s]);

    useEffect(() => {
        if (window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
            document.getElementById('ot-sdk-btn').addEventListener('click', window.OneTrust.ToggleInfoDisplay);
        }
    }, []);

    return (
        <div className='layout'>
            {props.application ? (
                <>
                    <TopNav />
                    <Application>
                        {props.children}
                    </Application>
                    <Footer />
                </>
            ) : (
                <>
                    <TopNav />
                    {props.children}
                    <Footer />
                </>
            )}
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
    application: PropTypes.object
};

Layout.defaultProps = {

};

export default Layout;
