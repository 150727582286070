import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import fetch from '../../utils/fetch';
import { Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { UserContext } from '../../contexts/UserContextProvider';

const ResetPassword = props => {
    const { setToken } = useContext(UserContext);
    const { token } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShow] = useState(false);
    const [showConfirmPassword, setShowConfirm] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors([]);
    }, [password, confirmPassword]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (password.length < 8) {
            setErrorMessage('Your password must be at least 8 characters.');
            setErrors(['password']);
        } else if (!/[A-Z]/.test(password)) {
            setErrorMessage('Your password must have uppercase characters.');
            setErrors(['password']);
        } else if (!/[a-z]/.test(password)) {
            setErrors(['password']);
        } else if (!/\d/.test(password)) {
            setErrorMessage('Your password must have numeric characters.');
            setErrors(['password']);
        } else if (password !== confirmPassword) {
            setErrorMessage('Your passwords must match.');
            setErrors(['password']);
        } else {
            setErrors([]);
            setErrorMessage('');

            try {
                setLoading(true);
                const response = await fetch('/auth/sentry/reset-password', {
                    method: 'POST',
                    body: {
                        password,
                        token
                    }
                });
                if (response.error || response.errorCode) {
                    const errorDescription = response.error_description || response.message;
                    if (errorDescription === 'account not found') {
                        setErrorMessage('Account Not Found');
                        setErrors(['email']);
                    } else if (errorDescription.includes('Incorrect username or password')) {
                        setErrorMessage('Incorrect password');
                        setErrors(['password']);
                    } else {
                        setErrorMessage(response.message);
                        setErrors([response.errorCode]);
                    }
                } else {
                    setToken({ accessToken: response.access_token, refreshToken: response.refresh_token });
                    navigate('/account');
                }
                setLoading(false);
            } catch (err) {
                console.log('err', err);
            }
        }
    };

    return (
        <div className='ResetPassword'>
            <Modal show onHide={() => {}} className='ResetPassword__Modal ResetPassword__Modal__reset'>
                <Modal.Header>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex align-center Login__Modal__password'>
                        <TextField
                            className='right-10 '
                            padded={false}
                            placeholder='New Password*'
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            error={errors.includes('password')}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Icon className='button Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                    </div>
                    <div className='flex align-center Login__Modal__password'>
                        <TextField
                            className='right-10 '
                            padded={false}
                            placeholder='Confirm Password*'
                            value={confirmPassword}
                            type={showConfirmPassword ? 'text' : 'password'}
                            errorMessage={errorMessage || 'Passwords must match.'}
                            error={errors.includes('password')}
                            onChange={e => setConfirmPassword(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <Icon className='button Login__Modal__eye' name={`visibility-${showConfirmPassword ? 'off' : 'on'}`} onClick={() => setShowConfirm(!showConfirmPassword)} />
                    </div>
                    <Caption block>Password Requirements</Caption>
                    <Caption block number={2}>- At least 8 characters</Caption>
                    <Caption block number={2}>- At least 1 uppercase letter</Caption>
                    <Caption block number={2}>- At least 1 lowercase letter</Caption>
                    <Caption block number={2}>- At least 1 number</Caption>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container'>
                        <Button block type='primary' disabled={errors.length || !confirmPassword || !password} loading={loading} onClick={handleSubmit}>Reset Password</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ResetPassword;
