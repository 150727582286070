import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApplicationContext } from '../pages/Application';
import { Container } from '../modules/Grid';
import DirectingSample from './DirectingSample';

const Samples = props => {
    const { application } = useContext(ApplicationContext);
    const { samples } = application;

    const handleValid = (isSampleValid) => {
        props.setValidSample(isSampleValid);
    };

    return (
        <div className='Samples'>
            <Container>

                {samples.map((sample, idx) =>
                    (
                        <Fragment key={`sample-${idx}`}>
                            <hr className='Application__divider' />
                            <DirectingSample
                                sample={sample}
                                idx={idx}
                                key={`sample-${idx}`}
                                handleValid={handleValid}
                            />
                        </Fragment>
                    )
                )}
            </Container>
        </div>
    );
};

Samples.propTypes = {
    setValidSample: PropTypes.func
};

export default Samples;
