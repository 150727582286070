import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { REMOVE_UPLOAD, ADD_APPLICANT_UPLOAD } from '../../queries';
import { ApplicationContext } from '../pages/Application';
import Button from '../modules/Button';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import UploadFile from './UploadFile';
import agreement from '../../images/Launchpad_Submission_Agreement.pdf';

const Legal = props => {
    const { application, showErrors, setLegalValid, refetch } = useContext(ApplicationContext);
    const { applicants } = application;
    const [addApplicantUpload] = useMutation(ADD_APPLICANT_UPLOAD);
    const [removeUpload] = useMutation(REMOVE_UPLOAD);
    const [agreementLoading, setAgreementLoading] = useState('');
    const [agreements, setAgreements] = useState({});
    const [errors, setErrors] = useState([]);

    const updateErrors = () => {
        let errors = [];
        applicants.forEach(applicant => {
            if (!agreements[applicant.id] || !agreements[applicant.id].filename) {
                errors = [...errors, applicant.id];
            } else {
                errors = errors.filter(error => error !== applicant.id);
            }
        });
        setErrors(errors);
    };

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        const agreements = applicants.reduce((prev, curr) => {
            const agreement = { ...curr.uploads.find(upload => upload.name === 'agreement') };
            return { ...prev, [curr.id]: agreement || {} };
        }, {});
        setAgreements(agreements);
    }, [application]);

    useEffect(() => {
        updateErrors();
    }, [agreements]);

    useEffect(() => {
        setLegalValid(!errors.length);
    }, [errors]);

    const handleAgreementUpload = async ({ target: { validity, files: [file] } }, applicantId) => {
        if (validity.valid) {
            setAgreementLoading(applicantId);
            const ags = { ...agreements };
            if (!ags[applicantId]) {
                ags[applicantId] = {};
            }
            ags[applicantId].filename = file.name;
            const response = await addApplicantUpload({
                variables: {
                    applicantId,
                    name: 'agreement',
                    file
                }
            });
            const { filename, id } = response.data.addApplicantUpload;
            ags[applicantId].file = { filename, id };
            setAgreements(ags);
            setErrors(errors => errors.filter(error => error !== applicantId));
            setAgreementLoading('');
        }
    };

    const handleAgreementDelete = (id, applicantId) => {
        removeUpload({
            variables: {
                id
            }
        });
        const ags = { ...agreements };
        delete ags[applicantId];
        setAgreements(ags);
        setErrors(errors => [...errors, applicantId]);
    };

    return (
        <div className='Legal'>
            {applicants.map(applicant => (
                <Row className='spacing-md' key={applicant.id}>
                    <Col xs={10} md={3} lg={4} xsOffset={1} lgOffset={2}>
                        <Heading block number={2}>Disney Launchpad Submission Agreement ({applicant.firstName} {applicant.lastName})</Heading>
                        <Body block>Please download and sign our agreement, then upload the signed version.</Body>
                        <Body block>Required</Body>
                        <a href={agreement} download>
                            <Button type='secondary-outline' icon='download-b'>Download Submission Agreement</Button>
                        </a>
                    </Col>
                    <Col xs={10} md={6} lg={4} xsOffset={1} lgOffset={0}>
                        <UploadFile
                            handleUpload={(e) => handleAgreementUpload(e, applicant.id)}
                            fileTypes={['.pdf']}
                            file={agreements[applicant.id] || {}}
                            handleDelete={id => handleAgreementDelete(id, applicant.id)}
                            loading={agreementLoading === applicant.id}
                            filename={agreements[applicant.id] ? agreements[applicant.id].filename : ''}
                            error={showErrors && errors.includes(applicant.id)}
                            errorMessage='Please upload a file.'
                        />
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default Legal;
