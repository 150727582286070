import React, { useContext, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery, useMutation } from '@apollo/client';
import { GET_APPLICATION, CREATE_APPLICATION, ADD_APPLICANT, ADD_SAMPLE } from '../../queries';
import { Row, Container, Col } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';
import Button from '../modules/Button';
import Radio from '../modules/Radio';
import { UserContext } from '../../contexts/UserContextProvider';

export const StartApplicationContext = React.createContext();

const StartApplication = props => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const { user, role, setRole, applicationsOpen, setToken } = useContext(UserContext);
    const [applicationComplete, setApplicationComplete] = useState(false);
    const { data } = useQuery(GET_APPLICATION);
    const [createApplication] = useMutation(CREATE_APPLICATION);
    const [addApplicant] = useMutation(ADD_APPLICANT);
    const [addSample] = useMutation(ADD_SAMPLE);
    const isApplicationOpen = applicationsOpen === true;

    useEffect(() => {
        if (applicationsOpen === false) {
            navigate('/');
        }
    }, [applicationsOpen]);

    useEffect(() => {
        if (!cookies.get('refreshToken')) {
            navigate('/login');
        }
    }, [cookies.get('refreshToken')]);

    useEffect(() => {
        if (data && data.application) {
            if (data.application.status === 'IN_PROGRESS') {
                setApplicationComplete(false);
                navigate('/application');
            } else if (data.application.status === 'COMPLETED') {
                setApplicationComplete(true);
                window.scrollTo(0, 0);
            }
        }
    }, [data]);

    const logout = () => {
        cookies.remove('accessToken');
        cookies.remove('refreshToken');
        setToken(null);
        navigate('/login');
    };

    const handleChange = (value) => {
        setRole(value);
    };

    const handleCreate = async () => {
        try {
            await createApplication({ variables: { type: role } });
            await addApplicant({ variables: { type: role } });
            if (role === 'director' || role === 'both') {
                await addSample({ variables: { name: 'Directing Sample' } });
            }
            navigate('/application');
        } catch (err) {
            console.log('err', err);
        }
    };

    return (
        <StartApplicationContext.Provider value={{ }}>
            <div className='StartApplication'>
                {isApplicationOpen &&
                <>
                    <Helmet><title>Disney Launchpad: Shorts Incubator | Application</title></Helmet>
                    <div className='StartApplication__hero' />
                    <Container>
                        <div className='StartApplication__content'>
                            <Heading center number={1} className='StartApplication__title'>{applicationComplete ? 'Success!' : 'Your Application'}</Heading>
                            <Col lg={10} lgOffset={1}>
                                <Body center className='StartApplication__subtitle'>{applicationComplete ? 'Thanks for submitting your application!' : 'Please identify your role. If you are applying as a team of individuals, each member of your team must be willing to participate in the program.'}</Body>
                            </Col>
                            {!applicationComplete && (
                                <>
                                    <Row>
                                        <Col xs={8} xsOffset={1} smOffset={0} lgOffset={1}>
                                            <div className='flex align-baseline'>
                                                <Col lg={5} sm={7} className='Upload__radio__block'>
                                                    <Radio inline padded={false} handleChange={handleChange} selected={role === 'director'} value='director' />
                                                    <Heading number={4}>Director</Heading><br />
                                                    <div className='Upload__radio__text'>Apply here if you would like to be considered as a director (or directing team) or if additionally, you are applying with a writer (or writing team).</div>
                                                </Col>
                                                <Col lg={5} lgOffset={0} sm={7} className='Upload__radio__block'>
                                                    <Radio inline padded={false} handleChange={handleChange} selected={role === 'writer'} value='writer' />
                                                    <Heading number={4}>Writer</Heading><br />
                                                    <div className='Upload__radio__text'>Apply here if you have written (or co-written) a script for submission.</div>
                                                </Col>
                                                <Col lg={5} lgOffset={0} sm={7} className='Upload__radio__block'>
                                                    <Radio inline padded={false} handleChange={handleChange} selected={role === 'both'} value='both' />
                                                    <Heading number={4}>Writer/Director</Heading><br />
                                                    <div className='Upload__radio__text'>Apply here if you wrote (or co-wrote) a script for submission with intent to direct (or co-direct) it.</div>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className='flex center full-width spacing-md'>
                                            <Button onClick={handleCreate}>Next</Button>
                                        </div>
                                    </Row>
                                </>
                            )}
                            <Row className='spacing-lg StartApplication__footer'>
                                <Col sm={9} lg={10}>
                                    <Body className='StartApplication__footer__text'>You are signed in as {user ? user.email : ''}</Body>
                                </Col>
                                <Col sm={3} lg={2}>
                                    <Button color='error' type='tertiary' block onClick={logout}>Sign Out</Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </>}
            </div>
        </StartApplicationContext.Provider>
    );
};

StartApplication.propTypes = {

};

export default StartApplication;
