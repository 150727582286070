import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from '../modules/Grid';
import { Heading, Body, Caption } from '../modules/Typography';
import directorsTwo from '../directors/directorsTwo';
import laurelsTwo from '../directors/laurelsTwo';
import lgbtqa from '../../images/best_lgbtqa_trans.png';
import audience from '../../images/winner_audience2.png';
import naacp from '../../images/55th-Logo-1_modified.png';
import performance from '../../images/Laurel_Performance_Comedy.png';
import shortfilm from '../../images/Laurel_ShortFilm_Comedy.png';
// import { WidthContext } from '../../contexts/WidthContextProvider';

const SeasonTwo = (props) => {
    // const { isMobile } = useContext(WidthContext);
    const isOptedOut = OnetrustActiveGroups.includes(',C0002,');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        console.log(OnetrustActiveGroups);
    }, [isOptedOut]);

    return (
        <div className='Directors'>
            <Helmet>
                <title>Disney Launchpad: Shorts Incubator | Season Two Directors</title>
            </Helmet>
            <Container>
                <Heading
                    className='Directors__heading underline spacing-md'
                    center
                    number={1}
                >
                    Season Two
                </Heading>
                {isOptedOut && (
                    <Row className='flex center padded'>
                        <iframe
                            width='859'
                            height='488'
                            src='https://www.youtube.com/embed/tT8uN_80YoI'
                            title='YouTube video player'
                            frameBorder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                            allowFullScreen
                        />
                    </Row>
                )}
                <Row className='flex center padded'>
                    <Caption>All Short Films Available On Disney+.</Caption>
                </Row>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Festival Offical Selections
                        </Heading>
                        <Row className='flex center'>
                            <div className='laurelsCardsContainer'>
                                {laurelsTwo.map((laurel) => (
                                    <div key={laurel.id} className='laurelCard'>
                                        <a
                                            href={laurel.targetUrl}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            <img src={laurel.imageSrc} alt={laurel.imageAltText} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </Row>
                    </div>
                </div>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Nominated
                        </Heading>
                        <Row className='flex center'>
                            <img src={naacp} />
                            <img src={performance} />
                            <img src={shortfilm} />
                        </Row>
                    </div>
                </div>
                <div className='Directors__accolades'>
                    <div className='padded'>
                        <Heading center number={3}>
                            Winners
                        </Heading>
                        <Row className='flex center'>
                            <img src={lgbtqa} />
                            <img src={audience} />
                        </Row>
                    </div>
                </div>
                <hr className='Application__divider' />
                {directorsTwo.map((item) => (
                    <div key={item.projectName}>
                        <Col className='Directors__logline'>
                            <Heading number={4}>{item.project.projectName}</Heading>
                            <Col xs={10} md={6} lg={9}>
                                <span>
                                    <strong>Logline:</strong> {item.project.logline}
                                </span>
                            </Col>
                        </Col>
                        {item.project.finalists.map((finalist) => (
                            <Row className='Directors__card' key={finalist.name}>
                                <Col xs={12} md={4} lg={2} className='Directors__headshot'>
                                    <div id={finalist.tag} />
                                    <img
                                        src={finalist.image}
                                        className='Directors__headshot__image'
                                    />
                                </Col>
                                <Col
                                    xs={10}
                                    md={6}
                                    lg={9}
                                    lgOffset={1}
                                    className='Directors__card__text'
                                >
                                    <Body className='Directors__name'>{finalist.name}</Body>
                                    <Body
                                        number={2}
                                        className='Directors__description'
                                        dangerouslySetInnerHTML={{ __html: finalist.description }}
                                    />
                                    <br />
                                    <br />
                                </Col>
                            </Row>
                        ))}
                        <hr className='Application__divider' />
                    </div>
                ))}
                <div id='finalists' />
                <div className='Directors__finalist__text'>
                    <Heading number={3}>
                        With nearly two thousand applications, we had the pleasure of
                        getting to know so many talented filmmakers. We here at Disney
                        Launchpad aim to ensure that helping underrepresented filmmakers is
                        not a zero-sum game. For that reason, we're honored to share the
                        below list of talented filmmakers on the rise.
                    </Heading>
                    <br />
                    <br />
                    <Heading number={3}>Finalists</Heading>
                    <br />
                    <Body className='Directors__finalist__names'>
                        Alison-Eve Hammersley <Body number={2}>(Director)</Body>
                        <br />
                        Anthony Sneed <Body number={2}>(Writer/Director)</Body>
                        <br />
                        Austin S. Harris <Body number={2}>(Writer/Director)</Body>
                        <br />
                        Felipe Vargas <Body number={2}>(Director)</Body>
                        <br />
                        Jelena Woehr <Body number={2}>(Writer)</Body>
                        <br />
                        LeRon Lee <Body number={2}>(Writer/Director)</Body>
                        <br />
                        Ramiel Petros <Body number={2}>(Director)</Body>
                        <br />
                        Sarah Smith <Body number={2}>(Director)</Body>
                        <br />
                        Sarey Martin <Body number={2}>(Writer/Director)</Body>
                        <br />
                        Sisi Tang <Body number={2}>(Writer)</Body>
                        <br />
                        Sydney Ribot <Body number={2}>(Writer/Director)</Body>
                        <br />
                    </Body>
                    <br />
                    <br />
                    <Heading number={3}>Semifinalists</Heading>
                    <br />
                    <Body className='Directors__finalist__names'>
                        Bita Ghassemi <Body number={2}>(Director)</Body>
                        <br />
                        Darine Hotait <Body number={2}>(Director)</Body>
                        <br />
                        Frannie Williams <Body number={2}>(Director)</Body>
                        <br />
                        M.G. Evangelista <Body number={2}>(Director)</Body>
                        <br />
                        Stephanie Sandoval Lean <Body number={2}>(Director)</Body>
                        <br />
                        Tess Paras <Body number={2}>(Director)</Body>
                        <br />
                    </Body>
                </div>
            </Container>
        </div>
    );
};

export default SeasonTwo;
