import React from 'react';
import PropTypes from 'prop-types';
import { Caption, Body } from './Typography';
import MenuSurface from './MenuSurface';
import Icon from './Icon';

const Select = props => {
    const selectedOption = props.options.find(option => option.value === props.selectedValue) || {};

    return (
        <div className={[
            'Select',
            props.disabled ? 'Select--disabled' : null,
            props.error ? 'Select--error' : null,
            `Select--${props.styleType}`,
            props.className
        ].filter(i => i).join(' ')}
        >

            {props.label && <Caption faded={props.disabled} className='Select__label'>{props.label}</Caption>}

            <div className='Select__wrapper' onBlur={props.onBlur}>
                {props.icon && <Icon name={props.icon} className='Select__icon' />}
                <MenuSurface
                    searchable={props.searchable}
                    className='Select__MenuSurface'
                    options={props.options.map((option, index) => ({
                        ...option,
                        onClick: () => props.onChange({ value: option.value, index, id: props.id })
                    }))}
                >
                    <div className='Select__bar'>
                        <Body className={`Select__bar__text ${selectedOption.text ? '' : 'disabled-text'}`} number={2}>{selectedOption.text || props.placeholder}</Body>
                    </div>
                </MenuSurface>
            </div>
            {props.error && props.errorMessage && <Caption number={2} error className='TextField__error'>{props.errorMessage}</Caption>}
        </div>
    );
};

export default Select;

Select.propTypes = {
    // function to handle select change event
    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    // options in the select drop down
    options: PropTypes.array,

    // whether or not the select input is disabled
    disabled: PropTypes.bool,

    // item that has been selected
    selectedValue: PropTypes.string,

    // className to pass down to the tags
    className: PropTypes.string,

    // label to associate with the select menu
    label: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,

    // type of style the select is
    styleType: PropTypes.oneOf(['default', 'dark']),

    // icon to showcase left of the select menu
    icon: PropTypes.string,
    id: PropTypes.string,

    // whether or not the select field is searchable
    searchable: PropTypes.bool,
    error: PropTypes.bool
};

Select.defaultProps = {
    disabled: false,
    className: '',
    styleType: 'default',
    searchable: false
};
