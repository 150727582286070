import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../modules/ProgressBar';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { Body, Caption } from '../modules/Typography';

const UploadFile = props => {
    const hash = Math.random();
    if (props.filename && props.loading) {
        return (
            <>
                <Body block className='Upload__filename'>{props.filename}</Body>
                <ProgressBar shimmer progress={100} className='Upload__progress' />
            </>
        );
    } else if (props.filename) {
        return (
            <>
                <div className='flex align-flex-end'>
                    <div className='flex'>
                        <Icon name='file-pdf' className=' Upload__icon info-text' />
                        <Body block color='info' className='Upload__filename'>{props.filename}</Body>
                    </div>
                    <Button type='tertiary' color='error' onClick={() => props.handleDelete(props.file.id)}>Delete</Button>
                </div>
            </>
        );
    } else {
        return (
            <>
                <label
                    htmlFor={`file-upload-${hash}`}
                    className='Button Upload__button Button--primary Button--regular Button--color-default Button--block'
                >
                    UPLOAD PDF
                </label>
                <input
                    className='Button Upload__button Button--primary Button--regular Button--color-default Button--block'
                    id={`file-upload-${hash}`}
                    type='file'
                    required
                    onChange={props.handleUpload}
                    style={{ display: 'none' }}
                    accept={props.fileTypes.join(',')}
                />
                {props.error && <Caption number={2} error>{props.errorMessage}</Caption>}
            </>
        );
    }
};

UploadFile.propTypes = {
    fileTypes: PropTypes.array,
    handleUpload: PropTypes.func
};

export default UploadFile;
