import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { Heading } from './Typography';

const CustomModal = (props) => (
    <Modal show={props.show} onHide={props.handleClose} centered>
        <Modal.Header>
            <Heading className='modal-header-text' number={3}>{props.title}</Heading>
            <Button variant='link' onClick={props.handleClose}>
                Close
            </Button>
        </Modal.Header>
        <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
        </Modal.Body>
    </Modal>
);

CustomModal.propTypes = {
    show: PropTypes.bool,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    content: PropTypes.string
};

export default CustomModal;
