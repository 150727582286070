import React, { useContext, useState, useEffect } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { isIE } from 'react-device-detect';
import { Col } from '../modules/Grid';
import { FaqContext } from '../pages/Faqs';

const FaqNav = props => {
    const { faqs } = useContext(FaqContext);
    const [isTop, setTop] = useState(true);
    const [isDesktopTop, setDesktopTop] = useState(true);
    const [isTabletTop, setTabletTop] = useState(true);
    const [selectedSection, setSection] = useState(faqs[0].section);

    const listenForWindowTop = () => {
        const top = window.scrollY < 100;
        const desktopTop = window.scrollY < 302;
        const tabletTop = window.scrollY < 207;
        setTop(top);
        setDesktopTop(desktopTop);
        setTabletTop(tabletTop);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        document.addEventListener('scroll', listenForWindowTop);
        return () => {
            document.removeEventListener('scroll', listenForWindowTop);
        };
    }, []);

    return (
        <Col xs={12} lg={2}>
            <Nav
                variant='pills'
                className={
                    isIE
                        ? 'flex column Faqs__Nav'
                        : `flex column Faqs__Nav ${
                            isDesktopTop ? '' : 'nav-fixed-desktop'
                        } ${
                            isTabletTop ? '' : 'nav-fixed-tablet'
                        }`
                }
            >
                {faqs.map(group => (
                    <Nav.Item key={group.section}>
                        <Nav.Link eventKey={group.section}>
                            {group.section}
                        </Nav.Link>
                    </Nav.Item>
                ))}
                <NavDropdown
                    title={selectedSection}
                    id='collapsible-nav-dropdown'
                    className={`${
                        isTop ? '' : 'nav-fixed-mobile'
                    }`}
                    onSelect={(value) => setSection(value)}
                >
                    {faqs.map(group => (
                        <NavDropdown.Item
                            eventKey={group.section}
                            key={group.section}
                        >
                            {group.section}
                        </NavDropdown.Item>
                    ))}
                </NavDropdown>
            </Nav>
        </Col>
    );
};

export default FaqNav;
