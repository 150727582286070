import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_APPLICATION, UPDATE_APPLICATION_STEP, UPDATE_APPLICATION_STATUS } from '../../queries';
import { Container } from '../modules/Grid';
import { Heading, Caption } from '../modules/Typography';
import Button from '../modules/Button';
import Loading from '../modules/Loading';
import config from '../application/applicationConfig';
import { UserContext } from '../../contexts/UserContextProvider';
import PersonalInformation from '../application/PersonalInformation';
import ApplicationUploads from '../application/ApplicationUploads';
import Legal from '../application/Legal';
import EqualEmployment from '../application/EqualEmployment';
import ReviewApplication from '../application/ReviewApplication';

export const ApplicationContext = React.createContext();

const Application = props => {
    const navigate = useNavigate();
    const { user, token, applicationsOpen } = useContext(UserContext);
    const { data, loading, refetch } = useQuery(GET_APPLICATION);
    // const [deleteApplication] = useMutation(DELETE_APPLICATION);
    const [updateApplicationStep] = useMutation(UPDATE_APPLICATION_STEP);
    const [updateApplicationStatus] = useMutation(UPDATE_APPLICATION_STATUS);

    const [stepIdx, setStep] = useState(0);
    const [showErrors, setErrors] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [validApplicants, setApplicantsValid] = useState(false);
    const [validUploads, setUploadsValid] = useState(false);
    const [validLegal, setLegalValid] = useState(false);
    const [validEeo, setEeoValid] = useState(false);
    const validSections = [validApplicants, validUploads, validLegal, validEeo, true];
    const isApplicationOpen = applicationsOpen === true;

    useEffect(() => {
        if (applicationsOpen === false) {
            navigate('/');
        }
    }, [applicationsOpen]);

    useEffect(() => {
        if (!user || !token || !token.accessToken) {
            navigate('/login');
        }
    }, [user, token]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [stepIdx]);

    if (loading) return <Loading />;
    const { application } = data;

    const steps = ['Personal Information', 'Uploads', 'Legal', 'Equal Employment', 'Review'];

    const content = [
        <PersonalInformation key='personal' />,
        <ApplicationUploads key='uploads' />,
        <Legal key='legal' />,
        <EqualEmployment key='eeo' />,
        <ReviewApplication key='review' />
    ];

    const handleNext = async () => {
        const isValid = validSections[stepIdx];
        if (!isValid) {
            setErrors(true);
        } else if (stepIdx < steps.length - 1) {
            if (stepIdx > application.step - 2) {
                await updateApplicationStep({ variables: { step: stepIdx + 2 } });
                refetch();
            }
            setStep(stepIdx + 1);
            window.scrollTo(0, 0);
            setErrors(false);
        } else {
            await updateApplicationStatus({ variables: { status: 'COMPLETED' } });
            navigate('/account');
        }
    };

    const handlePrevious = () => {
        if (stepIdx > 0) {
            setStep(stepIdx - 1);
            window.scrollTo(0, 0);
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    // const handleDelete = async () => {
    //     await deleteApplication();
    //     navigate('/account');
    // };

    const handleStep = (idx) => {
        if (idx <= application.step - 1) {
            setStep(idx);
        }
    };

    const { states, hearOptions, sampleTypes, writingSampleTypes, genres, races, ethnicities, genders, veterans, disabilities, employees } = config;

    return (
        <ApplicationContext.Provider value={{
            states,
            hearOptions,
            sampleTypes,
            writingSampleTypes,
            genres,
            races,
            ethnicities,
            genders,
            veterans,
            disabilities,
            employees,
            application,
            refetch,
            setStep,
            setLoading,
            setApplicantsValid,
            setUploadsValid,
            setLegalValid,
            setEeoValid,
            showErrors
        }}
        >
            <div className='Application'>
                {isApplicationOpen &&
                <Container>
                    <div className='Application__container'>
                        <Helmet><title>Disney Launchpad: Shorts Incubator | Application</title></Helmet>
                        <div className='Application__steps flex space-around align-flex-end'>
                            {steps.map((step, idx) => (
                                <div
                                    key={step}
                                    className={`pointer Application__step ${idx === stepIdx ? 'active' : ''} ${idx < application.step - 1 && idx !== stepIdx ? 'complete' : ''}`}
                                    onClick={() => handleStep(idx)}
                                >
                                    <Heading block center className='Application__step__text' number={6}>{step}</Heading>
                                    <div className='Application__step__circle'><span className='Application__step__number'>{idx + 1}</span></div>
                                </div>
                            ))}
                        </div>
                        <Container>
                            {/*  <Button onClick={handleDelete}>TEMP DELETE</Button> */}
                            {content[stepIdx]}
                            <div className='flex column align-flex-end'>
                                <div className='Application__buttons flex align-flex-end'>
                                    <div>
                                        <Button type='tertiary' color='error' onClick={handleCancel}>Cancel</Button>
                                        {stepIdx > 0 && <Button type='tertiary' onClick={handlePrevious}>{'< Previous'}</Button>}
                                    </div>
                                    <div>
                                        {/* <Button size='large' className='Application__button Application__button__save' type='secondary-outline'>{isLoading ? 'Saving' : 'Save'}</Button> */}
                                        <Button loading={isLoading} size='large' className='Application__button' type='primary' onClick={handleNext}>{stepIdx === 4 ? 'Submit' : 'Next'} </Button>
                                    </div>
                                </div>
                                {stepIdx === 4
                                    ? <Caption className='Application__footer'>When you click 'Submit' you will not be able to make changes to your application.</Caption>
                                    : <Caption className='Application__footer'>Your application is automatically saved when you click 'Next.'</Caption>}
                                {showErrors && <Caption className='Application__footer error-text'>Please review your application for errors.</Caption>}
                            </div>
                        </Container>
                    </div>
                </Container>}
            </div>
        </ApplicationContext.Provider>
    );
};

export default Application;
