import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Container } from '../modules/Grid';
import { Heading, Body } from '../modules/Typography';
import Button from '../modules/Button';
import CreateAccountModal from '../login/CreateAccountModal';
import LoginModal from '../login/LoginModal';
import { UserContext } from '../../contexts/UserContextProvider';
import ForgotPasswordModal from '../login/ForgotPasswordModal';

const Login = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const [createOpen, setCreateOpen] = useState(false);
    const [forgotOpen, setForgotOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const { user, token, applicationsOpen } = useContext(UserContext);
    const isApplicationOpen = applicationsOpen === true;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (applicationsOpen === false) {
            navigate('/');
        }
    }, [applicationsOpen]);

    useEffect(() => {
        if (token && token.accessToken && location.pathname !== '/application') {
            navigate('/account');
        }
    }, [user, token]);

    const handleCreate = () => {
        setCreateOpen(!createOpen);
        setLoginOpen(false);
        setForgotOpen(false);
    };
    const handleLogin = () => {
        setLoginOpen(!loginOpen);
        setCreateOpen(false);
        setForgotOpen(false);
    };
    const handleForgot = () => {
        setForgotOpen(!forgotOpen);
        setCreateOpen(false);
        setLoginOpen(false);
    };

    return (
        <div className='Login'>
            {isApplicationOpen &&
            <>
                <Helmet><title>Disney Launchpad: Shorts Incubator | Login</title></Helmet>
                <Heading className='Login__heading' color='purple' center number={1}>Apply Now</Heading>
                <Container>
                    <Row className='Login__content'>
                        <Col md={4} mdOffset={1} className='flex column'>
                            <Heading number={2}>Create New Account</Heading>
                            <Body className='spacing-sm'>Create your Launchpad account to begin your application to the Disney Launchpad: Shorts Incubator.</Body>
                            <Button className='Login__padding' size='large' type='primary' block onClick={handleCreate}>New Application</Button>
                        </Col>
                        <Col className='Login__divider' md={1} mdOffset={1} />
                        <Col md={4} className='flex column'>
                            <Heading number={2}>Sign In To Your Account</Heading>
                            <Body className='spacing-sm'>If you’ve already started your application or applied to a past season of Launchpad, sign in to your account to continue.</Body>
                            <Button className='Login__button Login__padding' size='large' type='secondary-outline' block onClick={handleLogin}>Sign In</Button>
                        </Col>
                    </Row>
                </Container>
                <CreateAccountModal handleClose={handleCreate} open={createOpen} handleLogin={handleLogin} />
                <LoginModal handleClose={handleLogin} open={loginOpen} handleCreate={handleCreate} handleForgot={handleForgot} />
                <ForgotPasswordModal open={forgotOpen} handleClose={handleForgot} />
            </>}
        </div>
    );
};

export default Login;
