const faqs = [
    {
        section: 'General FAQ',
        questions: [
            {
                q: 'What is the Disney Launchpad Season Three (3) script theme?',
                a: 'The theme for Disney Launchpad Season Three (3) is FORWARD.'
            },
            {
                q: 'Can I keep my job and participate in the Disney Launchpad at the same time?',
                a: 'Yes, however, this is a rigorous program, for directors, and you must participate in ALL the required activities – no exceptions. You must be available to work on a full-time, first-priority basis for the Launchpad program during the period of principal photography, some of which will take place Monday through Friday.<br/><br/>The commitment for writers, though important, is less demanding, potentially allowing for the retention of full-time employment.'
            },
            {
                q: 'If I get another directing job while participating in the Launchpad program, can I take that other directing job?',
                a: 'Yes, if it does not interfere with your participation (including but not limited to classes, meetings with creative executives and principal photography) in the Launchpad program, and it is not a violation of the terms and conditions of The Walt Disney Company Confidentiality Agreement, your Director Service Agreement, or any other policies and practices of Disney.'
            },
            {
                q: 'If I get another writing job while participating in Disney Launchpad, can I take that other writing job?',
                a: 'Yes, if it does not interfere with your participation (including but not limited to classes, meetings with creative executives, behind-the-scenes, creative review meetings, etc.) in the Launchpad program, and it is not a violation of the terms and conditions of The Walt Disney Company Confidentiality Agreement, your Writer Service Agreement, or any other policies and practices of Disney.'
            },
            {
                q: 'Can I apply if I am outside the United States?',
                a: 'No, you must be physically present in the United States to apply.'
            },
            {
                q: 'If I am in the United States on a work visa, can I apply?',
                a: 'Participation in the Launchpad program is conditional based upon your unrestricted right to work lawfully in the United States throughout the duration of the Launchpad and any additional post-term obligations entered with Disney.'
            },
            {
                q: 'Will Disney sponsor work visas to participate in Disney Launchpad?',
                a: 'No, Disney will not sponsor work visas to participate in the Launchpad.'
            },
            {
                q: 'I’m a director. Will I be given a living stipend if I relocate to Southern California for Disney Launchpad?',
                a: 'Yes, as a director, you will be given a small living stipend (as determined by Disney) to use for living and relocation expenses if you live outside Southern California and temporarily relocate for the Launchpad program. You will be given this living stipend until post-production of your short is complete.'
            },
            {
                q: 'I’m a writer. Will I be given a living stipend if I relocate to Southern California for Launchpad?',
                a: 'Yes, as a writer, you will be given a small living stipend (as determined by Disney) to use for living and relocation expenses if you live outside Southern California and temporarily relocate for the Launchpad program. You will be given this living stipend for the duration of your services needed for the program.'
            },
            {
                q: 'How are you defining living in Southern California?',
                a: 'For purposes of the Launchpad program, Southern California is defined by the following zip codes: 90001 to 93066.'
            },
            {
                q: 'Will Disney provide travel, housing, and transportation arrangements during Launchpad?',
                a: 'No, Disney will not provide travel, housing, and transportation arrangements during Launchpad. It is up to the participant to arrange living accommodations. If you relocate to Southern California for Launchpad, the living stipend should be put towards this use.'
            },
            {
                q: 'Do I need to move to Southern California if I am chosen for Launchpad?',
                a: 'Yes, if you are a director chosen for Launchpad you must reside in Southern California for the duration of the program. If you relocate to Southern California for Launchpad, the small living stipend (discussed above) should be put towards this use. Writers have the option to relocate but this is not mandatory. If a writer chooses to relocate, they will be offered a stipend.'
            },
            {
                q: 'Do I need to attend all Launchpad classes if I am chosen for the program?',
                a: 'Yes, if you are a director chosen for Launchpad, you must attend all Launchpad classes. Writers will be required to attend classes that are determined relevant to their program participation.'
            },
            {
                q: 'What is Launchpad’s time commitment / availability requirement?',
                a: 'You must attend:<br/><br/>• All classes, which will be held on weeknights once or twice per week, and are scheduled to take place after normal work hours (7pm to 10pm PT) on the Disney studio lot in Burbank, CA. As written above, writers participating in the program will be required to attend relevant classes.<br/><br/>• Script development meetings (times to be coordinated around you and your creative executive mentors’ work schedules).<br></br>Directors must be available on a full-time, first-priority basis for the entire duration of prep and principal photography which can take place anytime during the week, including weekends, depending on the budget and production schedule. Writers are not required to be available during principal photography, but it is encouraged.<br></br>Pre-production (up to eight (8) weeks) and post-production (up to twelve (12) weeks) may be scheduled after normal work hours or on weekends during 2025.'
            },
            {
                q: 'Can I mail or email my application, including all submission materials to you?',
                a: 'No, you must complete the online application via our website.'
            },
            {
                q: 'Where is Launchpad located?',
                a: 'Classes for Launchpad will be held in Burbank, CA on the Disney studio lot. We plan for production to take place in Southern California.'
            },
            {
                q: 'If I have additional questions, who should I contact?',
                a: 'Should you have any questions, please first review our comprehensive FAQs <a href="/faq">here</a>.<br/><br/>In the event that your question has not been addressed, please contact us at <a href="mailto:launchpad@disney.com?Subject=Hello" target="_top">launchpad@disney.com</a> for further assistance.'
            }
        ]
    },
    {
        section: 'Eligibility FAQ',
        questions: [
            {
                q: 'Can I apply as both a writer and a director?',
                a: 'Yes. Applicants can submit for Disney Launchpad as either a writer, director, or writer/director. If applying as a writer/director, you must submit both a script for consideration and one directing sample.<br/><br/>If you are accepted as a writer, you and your script will be paired with a director. Conversely, if you are accepted as a director, you will be paired with a writer and their script.<br/><br/>If you are accepted as writer/director, you will both write and direct your script.'
            },
            {
                q: 'What happens if I apply solely as a director?',
                a: 'If you apply solely as a director, we will evaluate your directing abilities and consider you for a short film script submission we believe you would be a good fit for.'
            },
            {
                q: 'What happens if I apply solely as a writer?',
                a: 'Should your script be accepted, we will identify a director we believe will be a good fit to direct your script. Upon admittance to the program, you will write further drafts of the script based on the director’s and mentors’ feedback and additional vision.'
            },
            {
                q: 'How will the partnership between writers and directors go?',
                a: 'The director will be tasked with realizing a unique and authentic take, vision, and perspective on the writer’s submitted screenplay. The writer will then work with the director on any future drafts needed under the guidance of their studio mentors.'
            },
            {
                q: 'I am a director who would like to apply with a script I did not write, is that ok?',
                a: 'Absolutely! You would and your writer would need to submit a single application together, and the writer would need to be willing to participate in the program.'
            },
            {
                q: 'I’m a co-director (i.e., a member of a two (2)-person directing team). Am I allowed to apply?',
                a: 'Yes, you are allowed to apply as a co-director, if you demonstrate a shared history of co-directing experience, which will be evaluated on a case-by-case basis. If applying as co-directors (i.e., a two (2)-person directing team), input both of your information into a single application.<br></br>Co-directors should provide a history of shared directing samples as part of their resume (e.g., at least one scripted, live-action narrative work of at least five (5) minutes in length made within the past eight (8) years). Please include the project title, type of content, IMDb link (if applicable), and the year the work was released.<br></br>Note: Only one (1) of you needs to come from an underrepresented background and/or share a unique perspective.'
            },
            {
                q: 'Can co-writers apply as well?',
                a: 'Yes. If you have only written one piece together, we ask that you each submit a writing sample demonstrating previous work.<br></br>Note: Like directors, only one (1) of the writers needs to come from an underrepresented background and/or share a unique perspective.'
            },
            {
                q: 'What type of candidate can apply?',
                a: `You may apply as one (1) of the following:<br/><br/>
                1. Solo as a writer / director (with a script that you wrote yourself)<br/><br/>
                2. Solo director with a script from a writer or team of two (2) co-writers<br/><br/>
                3. Co-writers (with a script that you co-wrote)<br/><br>
                4. Co-directors (with a shared co-directing history) who write together, or with a script written by one (1) of you<br/><br/>
                5. Co-directors who apply with a script from a writer or team of two (2) co-writers maximum`
            },
            {
                q: 'Do I need to be a member of the DGA to apply?',
                a: 'No, you do not need to be a member of the DGA to apply.'
            },
            {
                q: 'Do I need to be a member of the WGA to apply?',
                a: 'No, you do not need to be a member of the WGA to apply.'
            },
            {
                q: 'Will writers be expected to attend classes?',
                a: 'They will be expected to attend the classes pertinent to them.'
            },
            {
                q: 'Who is ineligible to apply?',
                a: `You are NOT eligible to apply either as a director, writer, or writer/director, if you’ve done any of the following:<br/><br/>
                <ul>
                <li>Directed a theatrically distributed, scripted, non-documentary feature film.</li>
                <li>Directed two (2) or more scripted, non-documentary episodes of a network television, cable television, or premium streaming series.</li>
                <li>Directed two (2) or more scripted, non-documentary films, distributed on network television, cable television, or premium streaming service.</li>
                <li>Written a theatrically distributed, scripted, non-documentary feature film.</li>
                <li>Written more than two (2) episodes of a network television, cable, or premium streaming series.</li>
                <li>Written a scripted, non-documentary film, distributed on network television, cable television, or premium streaming service will be ineligible for consideration.</li>
                </ul>`
            },
            {
                q: 'Can I apply if I have an agent or manager?',
                a: 'Yes, you can apply if you have an agent or manager if you meet the applicant criteria.'
            },
            {
                q: 'Can my agent or manager submit my application or required materials for me?',
                a: 'No, you must submit your own application and all required materials.'
            },
            {
                q: 'I’m a writer-director who signed an option agreement for a script I wrote that has not yet been produced. Am I eligible to apply?',
                a: 'Yes, you are eligible to apply to the Launchpad program if you signed an option agreement for a script that has not been produced, but you cannot apply with the script you already have under option. If the script enters production, you can continue to work on that script while you are in the Launchpad program as long as it does not interfere with your commitment to Launchpad.'
            },
            {
                q: 'Are Disney employees eligible to apply?',
                a: 'Yes, all Disney employees may apply, if they obtain manager and human resources approval before submitting an application.'
            }
        ]
    },
    {
        section: 'Application Materials',
        questions: [
            {
                q: 'How long should my script be?',
                a: 'It must not exceed ten (10) pages in total.'
            },
            {
                q: 'Can I submit a script in another language?',
                a: 'Yes, however if your script is in another language, you must include an English translation in your script upload.'
            },
            {
                q: 'Can I submit a directing sample that is not in English?',
                a: 'Yes, however the online video link you provide must have English subtitles.'
            },
            {
                q: 'May I submit more than one script to apply for Disney Launchpad?',
                a: 'No, you may only submit one (1) script.'
            },
            {
                q: 'Can I be attached to more than one project submission to Launchpad?',
                a: 'No, you may only apply once, with one (1) project submission, to Launchpad.'
            },
            {
                q: 'Are you accepting animation scripts?',
                a: 'No, we are looking for live-action, original scripts with dialogue.'
            },
            {
                q: 'Are you accepting scripts with VFX?',
                a: 'Yes, we are accepting scripts with minimal VFX. Please note you will not get additional pre-production, production, or post-production days (or any corresponding increase in production budget) should you choose to include VFX in your short.'
            },
            {
                q: 'If selected for an interview as a director, what will the process entail?',
                a: `As a writer/director, you will be required to pitch your vision for your short film script.<br/><br/>
                As a director, you will be required to pitch your vision for a short film script we have selected for you through the application process.<br/><br/>
                In both cases, you will additionally be asked to discuss your vision and previous related work experience.<br/><br/>
                A pitch deck of up to ten (10) pages is encouraged but not required.`
            },
            {
                q: 'If selected for an interview as a writer, what will the process entail?',
                a: 'You will be asked to participate in round table interviews.'
            },
            {
                q: 'Is there an application fee paid to Disney for the Launchpad?',
                a: 'No, there is no application fee.'
            },
            {
                q: 'Can I submit a script based on pre-existing material I wrote that has already been produced or optioned?',
                a: 'No, the script you submit to Launchpad may not contain pre-existing material that has been produced or optioned.'
            },
            {
                q: 'Who owns the rights to the material submitted?',
                a: 'Disney retains no ownership rights to literary material that is submitted but not selected for Launchpad. However, if the director is selected and participates in Launchpad, Disney will be assigned the literary material submitted with the selected director’s application and will be granted all rights to such materials. Additionally, Disney will have all rights to the materials created during the Launchpad program.'
            },
            {
                q: 'I already have a first-look deal with another company or studio. Am I still allowed to apply to the Launchpad?',
                a: 'No, you may not apply to the Launchpad if you have a current first-look deal with another company or studio.'
            },
            {
                q: 'Should I write my short with further development in mind?',
                a: 'We are looking for the best stories and writing, as well as the vision and style of the director, when selecting projects. If your story is incredible but does not lend itself to further development, no problem. Focus on creating the best story possible. If your story does lend itself to further development, that’s great too. Strive to tell the best story. In the end, we are looking first and foremost for artists with a unique vision who have something meaningful to say.'
            },
            {
                q: 'As a participant in Launchpad, is it guaranteed that my short will be exhibited and/or developed further for Disney+?',
                a: 'No, exhibition and/or further development are not guaranteed.'
            },
            {
                q: 'May I seek help to apply to the Launchpad if I have a disability?',
                a: 'The Walt Disney Company and its Affiliated Companies are Equal Employment Opportunity employers and welcome eligible applicants including individuals with disabilities and veterans with disabilities. If you have a disability and believe you need a reasonable accommodation to apply to the Launchpad, please email <a href="mailto:launchpad@disney.com?Subject=Hello" target="_top">launchpad@disney.com</a> with your request. Please do not disclose the underlying condition or any genetic information in your request.'
            }
        ]
    },
    {
        section: 'Production',
        questions: [
            {
                q: 'Who will be hiring the crew to shoot these shorts?',
                a: 'Disney will oversee the production and crewing up of the shorts.'
            },
            {
                q: 'Will Disney sponsor work visas for crew or cast?',
                a: 'No, Disney will not sponsor work visas for crew or cast.'
            },
            {
                q: 'How is Disney Launchpad a “training program?”',
                a: 'The goal of Disney Launchpad is to elevate and prepare earlier career storytellers for future filmmaking opportunities at Disney. In order to achieve our goal, we leverage each stage of our program as an opportunity to expose filmmakers to standard studio filmmaking practices with the intent of eliminating the knowledge gap for our participants coming from an indie-filmmaking background. As such, creative collaboration, a teachable spirit, and grace under pressure are key characteristics we look for when reviewing and considering applicants.'
            },
            {
                q: 'Can I shoot my short on film?',
                a: 'No, all projects will be shot on professional digital cameras.'
            },
            {
                q: 'As a participant in Launchpad, is it guaranteed that my short will be produced?',
                a: 'No, production of your short is not guaranteed. Both you and Disney have the right to terminate your employment during the Launchpad program for any reason whatsoever with no obligation to the other during the Launchpad program.'
            },
            {
                q: 'As a participant in the Launchpad program, is it guaranteed that my short will be exhibited on Disney+?',
                a: 'All shorts created through the Launchpad program are intended to be exhibited on Disney+, and we are funding and producing these shorts with that intent in mind. However, Disney has the sole discretion to determine whether or not the short will be exhibited on Disney+, and such exhibition is not guaranteed.'
            },
            {
                q: 'Can I submit my short to a film festival?',
                a: 'Disney will have final say in all film festival submissions. Please note, shorts produced through the Launchpad program will be owned by Disney.'
            }
        ]
    }
];

export default faqs;
